import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const DailyCruising = ({ data }) => {
    const { translation } = data;
    return (
        <div>
            <div className="welcomeDiv">
                <h2>
                    {translation.DailyCruisingTitle}
                </h2>
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/dc1.jpg" className="w420mt20" alt="Daily cruising" />
            </div>
            <div className="regulaMiddleRow">
                <p>
                    {translation.DailyCruisingText}
                </p>
            </div>
            <div className="regulaMiddleRow">
                <img src="/grafika/dc2.jpg" width="410" className="w420mt20" alt="Daily cruising" />
            </div>
            <div className="regulaMiddleRow">
                <img src="/grafika/mana.jpg" width="410" className="w420mt20" alt="Daily cruising" />
            </div>
            <div className="regulaMiddleRow">
                <img src="/grafika/Mrtovac.jpg" width="410" className="w420mt20" alt="Daily cruising" />
            </div>

            <div>
                <div className="c2h22">
                </div>
            </div>

            <div className="regulaMiddleRow">
                MERRY FISHER 695 + SKIPPER  = 300 eur / {translation.Day}
            </div>

            <div>
                <div className="c2h22">
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    {translation.IncludedInPrice}
                    <ul>
                        <li>{translation.FullUseBoat}</li>
                        <li>{translation.SkipperExp}</li>
                        <li>{translation.VhcDscGps}</li>
                        <li>{translation.OutEngine}</li>
                        <li>{translation.KitchenUts}</li>
                        <li>{translation.FinCleaning}</li>
                        <li>{translation.Tax}</li>
                        <li>{translation.Insurance}</li>
                    </ul>
                </div>
                <div className="col-md-6">
                    {translation.NotIncludedInPrice}
                    <ul>
                        <li>{translation.FuelCost}</li>
                        <li>{translation.FoodDrinkExp}</li>
                    </ul>
                </div>
            </div>


            <div className="regulaMiddleRow">
                <p>
                    {translation.ProgramDesc.replace("{0}", "6").replace("{1}", "09:30-18:30")}
                </p>
            </div>

            <div className="regulaMiddleRow">
                <p>
                    {translation.ADVANTAGE}
                </p>
            </div>
            <div className="regulaMiddleRow">
                <img src="/grafika/dc_spec.jpg" className="w420mt20" alt="Daily cruising" />
            </div>

            <div>
                <div className="c2h22">
                </div>
            </div>

            <div className="regulaMiddleRow">
                {translation.SpecialOfferCruising}
            </div>

            <div className="regulaMiddleRow">
                SALI - PN TELASCICA - NP KORNATI - SALI
            </div>


            <div>
                <div className="c2h22">
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    {translation.IncludedInPrice}
                    <ul>
                        <li>{translation.FullUseBoat}</li>
                        <li>{translation.SkipperExp}</li>
                        <li>{translation.VhcDscGps}</li>
                        <li>{translation.OutEngine}</li>
                        <li>{translation.KitchenUts}</li>
                        <li>{translation.FinCleaning}</li>
                        <li>{translation.Tax}</li>
                        <li>{translation.Insurance}</li>
                        <li>{translation.FuelCost}</li>
                        <li>{translation.EntranceExp}</li>
                    </ul>
                </div>
            </div>

            <div className="regulaMiddleRow">
                <p>
                    {translation.ProgramDesc.replace("{0}", "6").replace("{1}", "09:30-18:00")}
                </p>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(DailyCruising));