import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { changeLanguage } from "../../actions/language";

const TopFlags = (props) => {
    return (
        <div className="top-flag-container" >
            <button onClick={() => props.changeLanguage('hr')} className="btn btn-link">
                <img src="/grafika/hr.png" className="top-flag-img" alt="Hrvatski" title="Hrvatski" />
            </button>
            <button onClick={() => props.changeLanguage('it')} className="btn btn-link">
                <img src="/grafika/it.png" className="top-flag-img" alt="Italiano" title="Italiano" />
            </button>
            <button onClick={() => props.changeLanguage('de')} className="btn btn-link">
                <img src="/grafika/ger.png" className="top-flag-img" alt="Deutsch" title="Deutsch" />
            </button>
            <button onClick={() => props.changeLanguage('en')} className="btn btn-link">
                <img src="/grafika/gbr.png" className="top-flag-img" alt="English" title="English" />
            </button>
        </div >
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps, { changeLanguage })(withRouter(TopFlags));
