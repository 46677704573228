import React from 'react'

export default function Video() {
    return (
        <div>
            <div className="welcomeDiv">
            </div>

            <div className="regulaMiddleRow">
                <iframe title='first-video'
                    width="100%" height="315"
                    src="//www.youtube.com/embed/wm_O6wtpyLE"
                    frameBorder="0" allowFullScreen></iframe>
            </div>

            <div className="subHeader">
            </div>

            <video controls className='embed-responsive'>
                <source src="/video/2023-10-10.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className="subHeader">
            </div>

            <video controls className='embed-responsive'>
                <source src="/video/2023-10-10-2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className="subHeader">
            </div>

            <video controls className='embed-responsive'>
                <source src="/video/2023-12-08.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className="subHeader">
            </div>

            <video controls className='embed-responsive'>
                <source src="/video/luce.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}
