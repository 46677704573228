import React from 'react';
import ReactDOM from 'react-dom';
import Application from './components/Application';
import SimpleReactLightbox from 'simple-react-lightbox';
import 'bootstrap/dist/css/bootstrap.min.css';
ReactDOM.render(
    <SimpleReactLightbox>
        <Application />
    </SimpleReactLightbox>,
    document.getElementById('root')
);