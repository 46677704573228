import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const AboutUs = ({ data }) => {
    const { translation } = data;
    return (
        <div>
            <div className="welcomeDiv">
                <h2>
                    {translation.AboutUsTitle}
                </h2>
            </div>

            <div className="regulaMiddleRow">
                <p className="leftAlignParagraph">
                    {translation.AboutUsText}
                </p>
            </div>
            <div className="regulaMiddleRow">
                <img src="/grafika/slike/sali/t.jpg" className="regulaMiddleRow" alt="tome" />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(AboutUs));
