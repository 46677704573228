const De = {
    LanguageSign: "ger",
    Home: "Home",
    HomeTitle: "Willkommen !",
    HomeText: "Entdecken sie das mittelmeer in seiner ursprünglichen form. Besuchen sie die allerschönsten ausflugsziele der Adria. Genießen sie eine einzigartige ausflugsfahrt im Kornaten archipel, entdecken sie das vergnügen das fisches auf hoher see und ruhen. Sie sich im letzten hafen vor den wunderschönen inseln der Kornaten aus.",
    DailyCruisingTitle: "Eintägige ausflugsfahren",

    DailyCruisingText: "Das programm der eintägigen Ausflugsfahrten ist speziell auf hotel und Apartment Gäste, welche rucht fur langere Zeit auf dem Meet bleiben können,abgestimmt. Sie entdeckten wunderschöne Inseln, unberührte natur, einsame badestrände, kristallklares wasser und genießen verschiedene kroatische Fischspezialitäten.",

    ADVANTAGE: "VORTEIL: Es gibt die Möglichkeit nach vorheriger Vereinbamng einen anderen Abfahrtskafen ais Sali an wählen. (z.B. Zadar, Su?ioéanj.",

    Yes: "Ja",
    No: "Nein",

    ProgramDesc: "Das programm ist für max {0} personen vorgesehen und findet zwischen {1}.",

    Day: "Tag",
    IncludedInPrice: "IM PREIS INBEGRIFFEN:",

    NotIncludedInPrice: "IM PREIS NICHT INBEGRIFFEN:",

    FullUseBoat: "Shiff und ausstattung",

    SkipperExp: "Skipper",

    VhcDscGps: "VHC, DSC, Color GPS chart plotter",
    OutEngine: "Außenbordmotor 10 PS",
    KitchenUts: "Küchengeräte",
    FinCleaning: "Endreinigung",
    Tax: "Steuer",
    Insurance: "Versicherung Schiff / Gäste",
    FuelCost: "Brennstoff preis",
    FoodDrinkExp: "Essen / Getränke preis (25 EUR / Tag / Gäste)",
    SpecialOfferCruising: "AUSFLUGSFAHRT - SPEZIALANGEBOT - 400 EUR / Tag",
    KornatiRestaurantExp: "Kosten für essen (fisch/fleisch) und getränke in einem restaurnat auf einer insel der Kornat",
    EntranceExp: "Kosten für eintrittkarten in den Naturpark Telascica und den Nationalpark Kornati",

    MultidayCruisingTitle: "Mehrtägige kreuzen",
    MultidayCruisingText: "Dieses Programm ist abgestimmt für jene Géste, welche auf einer unvergesslichen Aus?ugsfahrt die atemberaubende Schönheit Dalmatiens mit seinen Inseln, Grotten und malerischen Strénden entdecken wollen. Sie können Ihre Reiseziele und den Urlaubsverlauf selbst bestimmen, die Geschwindigkeit des Schiffes erlaubt es viele verschiedene Ziele anzufahren und danach zum Heimathafen Sali zurück zu kehren, wo ihr Appartement auf Sie wartet. Ziel dieses Programms ist, dass sie die Schönheiten der adriatischen Küste entdecken in Kombination mit Unterhaltung, Schwimmen, Meeresspezialitéten und komfortabler Unterkunft.",
    AccomodationAppartment: "Unterkunft im appartement",

    BedAndTowels: "Bettwäsche und handtücher",
    AdditionalOffer: "ZUSÄTZLICHES ANGEBOT: Zubereitung von meeresspezialitäten und getränken.",
    ProgramDescShort: "Das programm ist für max {0} personen vorgesehen",


    DailyCruising: "Eintägige kreuzen",

    MultidayCruising: "Mehrtägige kreuzen",

    DailyFishing: "Das eintägige fischen",

    EveningFishing: "Das nachtfischen",

    MultidayFishing: "Mehrtägige fischen",

    FishingPlus: "Fischen plus",

    Boat: "Das schiff",

    Port: "Der abfahrthafen",

    Apartments: "Die appartements",

    Contact: "Der kontakt",

    Gallery: "Die photogalerie",

    AboutUs: "Von uns",


    HowToFindUs: "Wie kann man uns finden:",

    ContactOwner: "Wie den eigentümer selbst zu kontaktieren:",

    ApartmentsSubtitle: "Die Appartements können Sie unabhängig von diesen Programm mieten",

    ApartmentsDistance: "Die Entfernung",

    ApartmentsOffer: "Das Angebot",

    ApartmentsPictures: "Die Photos des Hauses",

    ApartmentsObjectDesc: "Die Beschreibung des Hauses",

    ApartmentsApartments: "Appartements",

    ApartmentsApartment: "Appartement",

    ApartmentsPriceADay: "Preis / tag",

    Owner: "Der Eigentumer",

    HowToCome: "Wie kann man in Sali kommen?",

    GalleryFishing: "Fischen",

    GalleryGrill: "Grill",

    GalleryCruising: "Kreuzen",

    GalleryDiving: "Diving",

    HouseDesc: "Das House liegt in Sali, Dugi otok, nur 30m neben dem Meer an einem shonen und ruhigen Platz. Die Entfernung vom Appartment zum nachstgelegenen strand mit kristallklarem wasser ist 150m. In der naheren umgebung des hauses befind sich der marketplatz, das postamt, die ambulanz, restaurant und kaffehauser.",








    DailyFishingTitle: "Das Eintägige Fischen",
    DailyFishingText: "Das ziel unserer angelausflüge ist der Dugi otok - Kornaten Archipel. Sie konnen verschiedene angeltechniken zum einsatz bringen (wir empfehlen eine kombinationen aus langleinen-fischen und hochsee-fischen mit rutte und schnur). Das programm ist fur max 3 personen vorgesehen und findet zwichen 09:00 und 17:00 uhr statt.",

    EveningFishingTitle: "Das Nachtfischen",
    EveningFishingText: "Abfahrt ist in den späten Nachmittagsstunden vorn Hafen von Sali auf Dugi Otok. Das Nachtfischen und Calamari-Fischen ﬁndet in den Nachtstunden start, wir kehren am spaten Abend zurn Hafen zurﬂck. Je nach Gastewunsch werden wir Meeresspezialitéten direkt am Schiff zubereiten, oder je nach Vereinbarung zu einern spateren Zeitpunkt.",

    MultidayFishingTitle: "Das Mehrtägige Fischen",
    MultidayFishingText: "Dieses Angebot ist vorgesehen für wahre Enthusiasten unter den Fischern und fur Gaste, die das Meeresangeln in seiner Gesamtheit erleben wollen. Weil wir mehrere Tage auf See sind, um zu ﬁschen, sind außergewöhnliche resultate zu erwarten. Darüber hinaus werden sarntliche Angeltechniken präsentiert.",



    BoatTitle: "Das Boot",

    BoatText: "Jeanneau Merry Fisher 695 ist ein ideales Boot für das Angeln und für Ausflugsfahrten. Dank des grossen und geraurni gen Cockpits ist es sehr praktisch für das Fischen und kornfortabel Ausflugsfahrten. Der Diesel motor mit seinen 160 PS erlaubt eine Reisegeschwindigkeit von 24 Knoten und genugt somit allen Anforderungen des Fischens und Ausflugsfahrten. Das Boot ist mit rnodernsten Navigationsanlagen, wie Farb--GPS, digitalen Meereskarten, UKW DISC, Farb-Fishﬁnder/Echolot, Kornpass, ausgerustet. Daruber hinaus ist das Boot mit Bugstrahlruder, elektrischer Ankerwinde, WC,Heckdusche, HiFi, 4 Betten, Kuche, sowie Außenbordmotor ausgestattet und garantiert sornit Sicherheit und Kornfort für bis zu 6 Personen sarnt Bordpersonal.",

    PortTitle: "Der Abfahrtshafen",
    PortText: "Heimathafen ist der Fischer- und Tourismus Hafen in Sali im südlichen Teil derlnsel Dugi Otok, deren sudliches Ende nur wenige hundert Meter Von den Inseln der Kornaten entfernt ist.",

    PortText2: "Sali ist die grosste und wichtigste Ansiedlung auf Dugi Otok. Der Name Sali leitet sich vom Salz-Abbau ab und wurde das erste Mal im Jahr 1105 erwahnt. Sali hat eine Uber tausend Jahre alte Fischereitradition. Das erste geschriebene Dokument uber die Fischerei in der nahegelegenen Telascica datiert aus dem Jahre 990. Die Menschen in Sali leben vorwiegend Von Fischerei, Landwirtschaft (Weinbau, Olivenanbrau) und Tourismus. Auf Dugi Otok und der naheren Umgebung mit Uber 150 lnseln gibt es keine lndustriefabriken oder sonstige Quellen der Umweltverschmutzung, man ﬁndet nur unberuhrte Natur in Symbiose mit kristallklarem Meer. lm Hafen Von Sali gibt es eine Backerei, einige Lebensmittelladen, Restaurants, Kaffeehéuser, Bars, eine Ambulanz, ein Postamt, die Bucherei und das Buro des Hafenmeisters. In unmittelbarer Nahe beﬁndet sich der Kornati Nationalpark und derTelascica Naturpark. Sali ist ein idealer Ausgangspunkt zu allen Naturschonheiten der Adria und erfullt daher alle Anforderungen des Tourismus in der Region, wie Bootsfahrten, Segeln, Angeln, Tauchen, Ausﬂuge und jegliche sportlichen Aktivitéten.",

    ApartmentsTitle: "Die Appartements",
    ApartmentsText: "Diving",

    ContactTitle: "Diving",
    ContactText: "Soliten sie irgendwelche fragen haben, informationen benötigen oder reservierung vornhernen wollen wenden sie sich direkt an den eigentümer.",


    AboutUsTitle: "Über Uns",
    AboutUsText: "Tome ist ein Familienunternehmen, welches sich mit tourismus, Angeln und Passagiertransport beschäftigt. Der Gründer und Manager des Unternehmens ist Tornislav Caric (Tomé), er ist zugleicn Gastgeber, Skipper und Angelguide. Er entstammt der alten Fischerfamilie Aunedi und hat seine gesamte Kindneit mit Segeln und Angeln am Meer verbracht. Er hat sicn die Liebe und den Respekt für das Meer stets ernalten und segelte auf zahlreichen Schiffen rund um die Welt. Mit seinem umfassenden Wissen über das Segeln und die Navigation bracnte er es bis zum ersten Deckofﬁzier und Segelkapitan. Er teilt seine umfassende Erfahrung und sein Wissen über das Meer, sowie seine Leidenschaft fur das Fischen gerne mit seinen Gästen.",

    Price: "Preis",

    FishingEquipment: "Angelausrüstung",

    FishingLicense: "Fischerei lizenzen",

    FishingBaits: "Köder (sardinen, tintenfischen, meereswürmer, muscheln, hornhaut und rapala-kunstköder)",

    EveningFishingText2: "Das Ziel unserer Angelausflüge ist der Dugi Otok — Kornaten Archipel. Sie konnen verschiedene Angeltechniken zum Einsatz bringen (wir empfehlen eine Kombination aus Langleinen-Fischen und Calamari-Fischen mit Rute und Schnur). Das Programm ist fur max. 3 Personen vorgesehen und ﬁndet zwischen 18:00 und 24:00 Uhr start.",
    ProgramDescShortExt: "This program is intended for {0} persons max and lasts according to a previous agreement",
    MultidayFishingText2: "Neben täglicher angelausﬂuge und Meeresspezialitäten, bieten wir, für den höheren Komfort unserer Gäste eine Appartement Unterkunft an. Der Hafen Von Sali ist ein Teil des Kornaten-Archipels und ermdglicht so die Anwendung angeltechniken, Hochsee-Fischen inklusive.",


    FishingPlusTitle: "Fischen Plus",
    FishingPlusText: ` 7 NACHTE MIT HALBPENSION: <br/>Unkerkunft im appartment mittagessen <br/> (ver shiedene fischspezialitaten mit hausgemachten wein, olivenöl und gemüse, 25 EUR / Tag / Gäste) <br/>2 ANGELAUSFLUGE MIT LANGLEINEN: <br/>Kombination von verschiedenen langleinen - typen.Machmittag - Nacht.<br/>2 TINTENFISCH ANGELAUSFLUGE: <br/>Außerhalb der tintenfisch - saison wird eine andere art des fischens angeboten.Machmittag - Nacht.<br/>2 HOCHSEE - FISCHEN: Morgen - Machmittag.`,

    FishingPlusPackagePriceText: "GESAMTPREIS FÜR DAS ANGEBOTENE 800 EUR PRO TEILNEHMER<br/>Mindestteilnehmerzahl 2 und max 4 <br/>Verfügbar von 01.04.- 31.12 jedes jahr.",
    FishingPlusText2: ` 7 NACHTE MIT HALBPENSION: <br/>Unkerkunft im appartment mittagessen <br/> (ver shiedene fischspezialitaten mit hausgemachten wein, olivenöl und gemüse, 25 EUR / Tag / Gäste) <br/>2 TINTENFISCH ANGELAUSFLUGE: <br/>Außerhalb der tintenfisch - saison wird eine andere art des fischens angeboten.Machmittag - Nacht.<br/>1 AUSFPLUG IN DEN PN TELASCICA UND DEN NATIONAL PARK KORNATI: <br/>Morgen - Machmittag.`,
    FishingPlusPackagePriceText2: "GESAMTPREIS FÜR DAS ANGEBOTENE 600 EUR PRO TEILNEHMER<br/>Mindestteilnehmerzahl 3 und max 5 <br/>Verfügbar von 05.09.- 31.12 jedes jahr.",
    ResidencePermit: " KURTAXE<br/>1 EURO pro person täglich <br/>0.5 EUR pro kinder 12 - 18 jahre täglich <br/>0 EURO pro kinder unter 12 jahre.",
    ApartmentAdvantage: " VORTEILE für appartement Gäste: <br/>-ermäßigung für fischerei - und ausﬂugsprogramme um 15 % <br/>- gratis Fisch - Abendessen und Wein für alle Gäste, die mehr als 7 tage bleiben im Mai, Juni und September <br/>-gratis Fisch - Abendessen und Wein für alle Gaste, die mehr als 14 Tage bleiben im Juli und August <br/>-benutzung des hauseigenen Grills <br/>-mieten eines 4, 5 m Kunststoff Bootes mit 9.8 PS außenbordmotor moglich",
    Apartment4Text2: "Schlafzimmer (2 Betten), Tisch Stühlen und einem bad.",
    Apartment3Text2: "Das Appartement beﬁndet sich seitlich im Erdgeschoﬂ mit: Schlafzimmer (2 Betten], Kathe mit wohnzimmer (Moglichkeit eines Zusatzbetts), Badezimmer.",

    Apartment2Text2: "Das Appartement beﬁndet sich im Erdgeschoss mit: Schlafzimmer (2 Betten), Schlafzimmer (2 Betten), Schlafzimmer (1 Bett), Badezimmer, Balkon, grosse Terrasse und Kuche mit wohnzimmer.",

    Apartment1Text2: "Das Appartement beﬁndet sich im Erdgeschoss mit: Schlafzimmer (2 Betten), Schlafzimmer (2 Betten), Wohnzimmer (Moglichkeit eines Zusatzbetts), Kuche, Badezimmer, Toilette und grosse Terrasse mit Freiluftdusche.",

    ContactOwnerData: "capt. Tomislav Caric. <br/>Sali 23281, Dugi otok, Hrvatska <br/>tel. + 385(0)23 377 489 <br/>gsm. + 385(0)98 629 353 <br/>email: tomislav.caric2@gmail.com",

    ApartmentsSea: "Meer",
    ApartmentsBeach: "Baderstrand",
    ApartmentsBerth: "Hafen",
    ApartmentsAmbulance: "Ambulanz",


    ApartmentsExcursions: "Ausfluge",
    ApartmentsFishing: "Fischerei",
    ApartmentsDiving: "Tauchen",
    ApartmentsPets: "Tierie",

    ApartmentsBedsNumber: "Die betten",
    ApartmentsBarbecue: "Der rost",
    ApartmentsSize: "Die Große [m2]",
    ApartmentsTerrace: "Die Terrasse",
    ApartmentsCategory: "Die Kategorie",
    ApartmentsRoomsNumber: "Die zimmer",
    ApartmentsSeaView: "Die aussicht aufs meer",
    ApartmentsSatTV: "Sat TV LCD",
    ApartmentsBathroom: "Das bad",
    ApartmentsAirCondition: "Air-Condition",
    ApartmentsWashmachine: "Waschmaschine",
    ApartmentsDishWasher: "Spülmaschine",
    ApartmentsWIFI: "WIFI",
    ApartmentsFrigo: "Frigo",



    ContactaByCarTitle: "Mit dem auto",
    ContactaByCarText: "Es gibt der trajekt von Zadar bis Brbinj (cca 1h 30min). Von Brbinj muss man 20 km (cca 25 min) nach Sali fahren, wo euch der eigentumer erwartet.",
    ContactaWithoutCarTitle: "Ohne auto",
    ContactaWithoutCarText: "Sie konnenn mit dem schiff von Zadar nach Sali (cca 1h 15min), wo der eigentumer euch erwartet fahren.",
    ContactaSpecOfferTitle: "Ein besonderes angebot",
    ContactaSpecOfferText: "Der eigentumer kann euch von Zadar bis Sali wie vereinbart verschiffen (ca. 60 Min).",



    BoatLength: "Lange",
    BoatBeam: "Breite",
    BoatEngine: "Motor",
    BoatDraught: "Tiefgang",
    BoatBeds: "Betten",
    BoatWC: "WC",
    BoatFuel: "Brennstoff",
    BoatWater: "Wasser",
    BoatRadar: "Radar",

    FishingWeekPlus: "FISCHEN WOCHE PLUS",
    SquidFishingWeek: "SQUID FISCHEN WOCHE",
}

export default De;