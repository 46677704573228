import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const Port = ({ data }) => {
    const { translation } = data;
    return (
        <div>
            <div className="welcomeDiv">
                <h2>
                    {translation.PortTitle}
                </h2>
            </div>

            <div className="row">
                <div className="col-md-8">
                    <img src="/grafika/base_port.jpg" className="logoImg" alt="tome" />
                </div>
                <div className="col-md-4">
                    <p>
                        {translation.PortText}
                    </p>
                </div>
            </div>

            <div className="regulaMiddleRow">
                <p className="leftAlignParagraph">
                    {translation.PortText2}
                </p>
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/sali1.jpg" className="w49PrMt20" alt="tome" />
                <img src="/grafika/sali2.jpg" className="w49PrMt20" alt="tome" />
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(Port));