import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LeftMenuItem from './LeftMenuItem';

const NonHighlightedLeftMenu = ({ data }) => {
    const { translation } = data;
    return (
        <div className="nav flex-column nav-pills me-3" role="tablist" aria-orientation="vertical" id="mainMenuItemHolder">
            <LeftMenuItem pagePath="Home" translation={translation} />
            <LeftMenuItem pagePath="DailyCruising" translation={translation} />
            <LeftMenuItem pagePath="MultidayCruising" translation={translation} />
            <LeftMenuItem pagePath="DailyFishing" translation={translation} />
            <LeftMenuItem pagePath="EveningFishing" translation={translation} />
            <LeftMenuItem pagePath="MultidayFishing" translation={translation} />
            <LeftMenuItem pagePath="FishingPlus" translation={translation} />
            <LeftMenuItem pagePath="Boat" translation={translation} />
            <LeftMenuItem pagePath="RentABoat" translation={translation} />
            <LeftMenuItem pagePath="Port" translation={translation} />
            <LeftMenuItem pagePath="Apartments" translation={translation} />
            <LeftMenuItem pagePath="Contact" translation={translation} />
            <LeftMenuItem pagePath="Gallery" translation={translation} />
            <LeftMenuItem pagePath="AboutUs" translation={translation} />
            <LeftMenuItem pagePath="Video" translation={translation} />
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(NonHighlightedLeftMenu));