import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const MultidayCruising = ({ data }) => {
    const { translation } = data;
    return (
        <div>
            <div className="welcomeDiv">
                <h2>
                    {translation.MultidayCruisingTitle}
                </h2>
            </div>
            <div className="regulaMiddleRow">
                <img src="/grafika/mdc.jpg" className="w420mt20" alt="tome.hr" />
            </div>

            <div className="regulaMiddleRow">
                <p>
                    {translation.MultidayCruisingText}
                </p>
            </div>
            <div className="regulaMiddleRow">
                <img src="/grafika/mdc2.jpg" className="w420mt20" alt="tome.hr" />
            </div>
            <div className="regulaMiddleRow">
                <img src="/grafika/kornati351.jpg" className="w420mt20" alt="tome.hr" />
            </div>
            <div className="regulaMiddleRow">
                <img src="/grafika/Mana6.jpg" className="w420mt20" alt="tome.hr" />
            </div>

            <div className="regulaMiddleRow">
                MERRY FISHER 695 + SKIPPER  = 400 eur / {translation.Day}
            </div>
            <div>
                <div className="c2h22">
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {translation.IncludedInPrice}
                    <ul>
                        <li>{translation.FullUseBoat}</li>
                        <li>{translation.SkipperExp}</li>
                        <li>{translation.VhcDscGps}</li>
                        <li>{translation.OutEngine}</li>
                        <li>{translation.KitchenUts}</li>
                        <li>{translation.FinCleaning}</li>
                        <li>{translation.Tax}</li>
                        <li>{translation.Insurance}</li>
                        <li>{translation.AccomodationAppartment}</li>
                        <li>{translation.BedAndTowels}</li>
                        <li>Parking</li>
                    </ul>
                </div>
                <div className="col-md-6">
                    {translation.NotIncludedInPrice}
                    <ul>
                        <li>{translation.FuelCost}</li>
                        <li>{translation.FoodDrinkExp}</li>
                    </ul>

                </div>
            </div>
            <div className="regulaMiddleRow">
                <p>
                    {translation.ProgramDescShort.replace('{0}', 6)}
                </p>
            </div>
            <div className="regulaMiddleRow">
                <img src="/grafika/mdc3b.jpg" className="w420mt20" alt="tome.hr" />
            </div>
            <div className="regulaMiddleRow">
                <p>
                    {translation.ADVANTAGE}
                </p>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(MultidayCruising));