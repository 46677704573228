import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Flags from './Flags';
import Maps from '../maps';
import './RightSide.css';

const RightSide = ({ data }) => {
    const { translation } = data;
    return (
        <div>
            <Flags />
            <div className="h12vsc">
                {translation.HowToFindUs}
            </div>
            <div className="h12vsc">
                <Popup
                    trigger={<button className="maps-button"><img src="/grafika/ro.png" className="staticMapImg" alt="Map" title="Map" /></button>}
                    modal>
                    <Maps />
                </Popup>
            </div>
            <div className="h12vsc">
                {translation.ContactOwner}
            </div>
            <div className="h12vsc">
                tel. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +385&nbsp;&nbsp;&nbsp; (0)&nbsp;&nbsp;23
                &nbsp;377&nbsp; 489<br />
                gsm. &nbsp;&nbsp;&nbsp;&nbsp; +385 &nbsp;&nbsp;(0)&nbsp;&nbsp; 98 &nbsp;629&nbsp;
                353<br />
                e-mail. <a href="mailto:tomislav.caric2@gmail.com?subject=Customer Query">tomislav.caric2@gmail.com</a>
            </div>
            <div className="h12vsc d-none d-md-block">
                <img src="/grafika/indexzub.jpg" alt="fish" />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(RightSide));