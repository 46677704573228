import React from 'react'

export default function RentABoat() {
    return (
        <div>
            <div className="welcomeDiv">
                <h2>
                    Rent A Boat
                </h2>
            </div>

            <div className="subHeader">
                FISHER 17
            </div>

            <div className="row">
                <div className="col-lg-12">
                    PRICE LIST ( in EUR per day )
                    <ul>
                        <li>1 DAY - 140</li>
                        <li>3 DAYS - 130</li>
                        <li>7 DAYS - 110</li>
                        <li>14 DAYS - 100</li>
                    </ul>
                </div>
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/fisher1.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/fisher2.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="subHeader">
                PASARA 4,5
            </div>

            <div className="row">
                <div className="col-lg-12">
                    PRICE LIST ( in EUR per day )
                    <ul>
                        <li>1 DAY - 70</li>
                        <li>3 DAYS - 60</li>
                        <li>7 DAYS - 50</li>
                        <li>14 DAYS - 40</li>
                    </ul>
                </div>
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/pasara.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/pasara2.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="subHeader">

            </div>

            <div className="regulaMiddleRow">
                <p className="leftAlignParagraph">
                    Gasoline is not included in stated prices.
                </p>
                <p className="leftAlignParagraph">
                    According to Croatian law, it is obligatory to have license to any type of motor boat.
                </p>
            </div>
        </div>
    )
}
