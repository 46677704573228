import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const DailyFishing = ({ data }) => {
    const { translation } = data;
    return (
        <div>
            <div className="welcomeDiv">
                <h2>
                    {translation.DailyFishingTitle}
                </h2>
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/df.jpg" className="w420mt20" alt="tome.hr" />
            </div>

            <div className="regulaMiddleRow">
                <p>
                    {translation.DailyFishingText}
                </p>
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/df2.jpg" className="w49PrMt20" alt="tome.hr" />
                <img src="/grafika/df2B.jpg" className="w49PrMt20" alt="tome.hr" />
            </div>

            <div className="regulaMiddleRow">
                {translation.Price} 350 - 500 eur
            </div>

            <div className="row">
                <div className="col-lg-12">
                    {translation.IncludedInPrice}
                    <ul>
                        <li>{translation.SkipperExp}</li>
                        <li>{translation.FishingEquipment}</li>
                        <li>{translation.FishingLicense}</li>
                        <li>{translation.FishingBaits}</li>
                        <li>{translation.FuelCost}</li>
                        <li>{translation.VhcDscGps}</li>
                        <li>{translation.OutEngine}</li>
                        <li>{translation.FinCleaning}</li>
                        <li>{translation.Insurance}</li>
                        <li>{translation.Tax}</li>
                    </ul>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    {translation.NotIncludedInPrice}
                    <ul>
                        <li>{translation.FoodDrinkExp}</li>
                    </ul>
                </div>
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/df3.jpg" className="w49PrMt20" alt="tome" />
                <img src="/grafika/df3b.jpg" className="w49PrMt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <p>
                    {translation.ADVANTAGE}
                </p>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(DailyFishing));