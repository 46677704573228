import React, { useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LeftMenuItem from './LeftMenuItem';

const LeftMenu = ({ data }) => {
    const { translation } = data;
    const [selection, setSelection] = useState('Home');
    return (
        <div className="nav flex-column nav-pills me-3" role="tablist" aria-orientation="vertical" id="mainMenuItemHolder">
            <LeftMenuItem onClick={() => setSelection('Home')} pagePath="Home" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('DailyCruising')} pagePath="DailyCruising" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('MultidayCruising')} pagePath="MultidayCruising" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('DailyFishing')} pagePath="DailyFishing" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('EveningFishing')} pagePath="EveningFishing" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('MultidayFishing')} pagePath="MultidayFishing" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('FishingPlus')} pagePath="FishingPlus" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('Boat')} pagePath="Boat" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('RentABoat')} pagePath="RentABoat" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('Port')} pagePath="Port" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('Apartments')} pagePath="Apartments" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('Contact')} pagePath="Contact" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('Gallery')} pagePath="Gallery" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('AboutUs')} pagePath="AboutUs" translation={translation} selection={selection} />
            <LeftMenuItem onClick={() => setSelection('Video')} pagePath="Video" translation={translation} selection={selection} />
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(LeftMenu));