import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const MultidayFishing = ({ data }) => {
    const { translation } = data;
    return (
        <div>
            <div className="welcomeDiv">
                <h2>
                    {translation.MultidayFishingTitle}
                </h2>
            </div>

            <div className="regulaMiddleRow">
                <p className="specialTextBlcok">
                    {translation.MultidayFishingText}
                </p>
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/mdf.jpg" className="contenTable" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <p className="specialTextBlcok">
                    {translation.MultidayFishingText2}
                </p>
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/mf1.jpg" className="w49PrMt20" alt="tome" />
                <img src="/grafika/mf2.jpg" className="w49PrMt20" alt="tome" />
            </div>


            <div className="regulaMiddleRow">
                {translation.Price} 400 - 500 eur/{translation.Day}
            </div>

            <div className="row">
                <div className="col-lg-12">
                    {translation.IncludedInPrice}
                    <ul>
                        <li>{translation.SkipperExp}</li>
                        <li>{translation.FishingEquipment}</li>
                        <li>{translation.FishingLicense}</li>
                        <li>{translation.FishingBaits}</li>
                        <li>{translation.FuelCost}</li>
                        <li>{translation.VhcDscGps}</li>
                        <li>{translation.OutEngine}</li>
                        <li>{translation.FinCleaning}</li>
                        <li>{translation.Insurance}</li>
                        <li>{translation.Tax}</li>
                        <li>{translation.AccomodationAppartment}</li>
                        <li>{translation.BedAndTowels}</li>
                        <li>{translation.FinCleaning}</li>
                        <li>Parking</li>

                    </ul>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    {translation.NotIncludedInPrice}
                    <ul>
                        <li>{translation.FoodDrinkExp}</li>
                    </ul>
                </div>
            </div>

            <div className="regulaMiddleRow">
                <p>
                    {translation.ProgramDescShortExt.replace("{0}", "3")}
                </p>
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/mf3.jpg" className="w420mt20" alt="tome" />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(MultidayFishing));