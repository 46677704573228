import React from 'react'

export default function Links() {
    return (
        <div className='d-none d-md-block'>
            <ul className="nav justify-content-center" >
                <li className="nav-item">
                    <a className="nav-link" href="http://www.dugiotok.hr">Dugi Otok</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="http://www.telascica.hr">Telascica</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="http://www.lincarnica.com">Lincarnica</a>
                </li>
            </ul >
        </div>
    )
}
