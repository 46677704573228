import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from "react-redux";
import Layout from "./layout";
import Home from "./home";
import DailyCruising from './dailycruising';
import MultidayCruising from './multidaycruising';
import DailyFishing from './dailyfishing';
import EveningFishing from './eveningfishing';
import MultidayFishing from './multidayfishing';
import FishingPlus from './fishingplus';
import Boat from './boat';
import RentABoat from './rentaboat';
import Port from './port';
import Apartments from './apartments';
import Contact from './contact';
import AboutUs from './aboutus';
import Video from './video';
import Gallery from './gallery';
import './site.css';
import store from '../store';

const Application = () => {
  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Route path="/" exact component={Home} />
          <Switch>
            <Route path="/DailyCruising" exact component={DailyCruising} />
            <Route path="/MultidayCruising" exact component={MultidayCruising} />
            <Route path="/DailyFishing" exact component={DailyFishing} />
            <Route path="/EveningFishing" exact component={EveningFishing} />
            <Route path="/MultidayFishing" exact component={MultidayFishing} />
            <Route path="/FishingPlus" exact component={FishingPlus} />
            <Route path="/RentABoat" exact component={RentABoat} />
            <Route path="/Boat" exact component={Boat} />
            <Route path="/Port" exact component={Port} />
            <Route path="/Apartments" exact component={Apartments} />
            <Route path="/Contact" exact component={Contact} />
            <Route path="/Aboutus" exact component={AboutUs} />
            <Route path="/Gallery" exact component={Gallery} />
            <Route path="/Video" exact component={Video} />
          </Switch>
        </Layout>
      </Router>
    </Provider>
  );
}

export default Application;
