import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import './Home.css';
const Home = ({ data }) => {
    const { translation } = data;
    return (
        <div className="middleContent">
            <div className="welcomeDiv">
                <h2>
                    {translation.HomeTitle}
                </h2>
            </div>
            <div className="row iregularMiddleRow">
                <div className="row">
                    <p>
                        {translation.HomeText}
                    </p>
                </div>
                <div className="row slika">
                    <img src="/grafika/more.jpg" alt="home" />
                    <img src="/grafika/main_image.jpg" className="top" alt="home" />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(Home));
