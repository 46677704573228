import en from './en';
import de from './de';
import hr from './hr';
import it from './it';

export const getTranslation = (lan = 'en') => {
    if (lan === 'de') {
        return de;
    }
    if (lan === 'hr') {
        return hr;
    }
    if (lan === 'it') {
        return it;
    }
    return en;
}