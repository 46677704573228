import React, { Component } from 'react'
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import './maps.css';

export default class Maps extends Component {
  constructor(props) {
    let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow
    });

    L.Marker.prototype.options.icon = DefaultIcon;
    super(props);
  }
  render() {
    const position = [43.93683, 15.165];
    const popupPosition = [43.93683 - 40, 15.165 + 10];
    return (
      <MapContainer className="map-container" center={position} zoom={14} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup position={popupPosition}>
            <img src="/grafika/kuca.jpg" alt="tome" className="map-popup" />
          </Popup>
        </Marker>
      </MapContainer>
    )
  }
}
