import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const Contact = ({ data }) => {
    const { translation } = data;
    return (
        <div>
            <div className="welcomeDiv">

            </div>


            <div className="subHeader">
                {translation.Contact}:
            </div>


            <div className="regulaMiddleRow">
                <p className="leftAlignParagraph">
                    {translation.ContactText}
                </p>
            </div>


            <div className="subHeader">
                {translation.Owner}:
            </div>

            <div className="regulaMiddleRow">
                <p className="leftAlignParagraph" dangerouslySetInnerHTML={{ __html: translation.ContactOwnerData }}>
                </p>
            </div>

            <div className="subHeader">
                {translation.HowToCome}
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="subSubHeader">
                        <p>
                            {translation.ContactaByCarTitle}
                        </p>
                    </div>
                    <div className="contactTextBlock">
                        <p>
                            {translation.ContactaByCarText}
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <img src="/grafika/trajekt.jpg" className="img-fluid" alt="tome" />
                    <a href="http://www.jadrolinija.hr">www.jadrolinija.hr</a>
                </div>
            </div>


            <div className="row">
                <div className="rulerLineCustom"></div>
            </div>


            <div className="row">
                <div className="col-md-6">
                    <div className="subSubHeader">
                        <p>
                            {translation.ContactaSpecOfferTitle}
                        </p>
                    </div>
                    <div className="contactTextBlock">
                        <p>
                            {translation.ContactaSpecOfferText}
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <img src="/grafika/tomobrod.png" className="img-fluid" alt="tome" />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(Contact));