import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const Boat = ({ data }) => {
    const { translation } = data;
    return (
        <div>

            <div className="welcomeDiv">
                <h2>
                    {translation.BoatTitle}
                </h2>
            </div>


            <div className="regulaMiddleRow">
                <div className="row">
                    <div className="col-md-9">
                        <img src="/grafika/brod.gif" className="logoImg" alt="tome" />
                    </div>
                    <div className="col-md-3">
                        <div className="row colJustAlign"><b>{translation.BoatLength}:</b> 8.20m</div>
                        <div className="row colJustAlign"><b>{translation.BoatBeam}:</b> 2.76m</div>
                        <div className="row colJustAlign"><b>{translation.BoatEngine}:</b> 160 hp</div>
                        <div className="row colJustAlign"><b>{translation.BoatDraught}:</b> 0.75m</div>
                        <div className="row colJustAlign"><b>{translation.BoatBeds}:</b> 2+2</div>
                        <div className="row colJustAlign"><b>{translation.BoatWC}:</b> 1</div>
                        <div className="row colJustAlign"><b>{translation.BoatFuel}:</b> 160 l</div>
                        <div className="row colJustAlign"><b>{translation.BoatWater}:</b> 100 l</div>
                        <div className="row colJustAlign"><b>{translation.BoatRadar}:</b> 1</div>
                    </div>
                </div>
            </div>

            <div className="regulaMiddleRow">
                <p className="leftAlignParagraph">
                    {translation.BoatText}
                </p>
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/br001.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/br002.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/br003.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/br2.jpg" className="w420mt20" alt="tome" />
            </div>


            <div className="regulaMiddleRow">
                <img src="/grafika/br1.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/br3.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/br4.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/br5.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/br6.jpg" className="w420mt20" alt="tome" />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(Boat));