const Hr = {
    LanguageSign: "hr",
    Home: "Početna",
    HomeTitle: "Dobrodošli !",
    HomeText: "Otkrijte duh mediterana kakav je nekad bio. Posjetite najatraktivnije lokacije Jadrana. Uživajte u jedinstvenom krstarenju kornatskim arhipelagom, osjetite draži ribolova i odmorite se u posljednjoj luci prije kamene divote Kornata.",
    DailyCruisingTitle: "Dnevna krstarenja",

    DailyCruisingText: "Program je namjenjen za sve one koji nemaju vremen i mogućnosti za duži boravak na moru. Za sve one smještene u hotelskim i apartmanskim aranžmanima a koji bi ipak htjeli uižvati no našim prelijepim otocima, netaknutim uvalama, usamljenim plažama, kristalnim žalima i raznovrsnim morskim specijalitetima.",

    ADVANTAGE: "Mogućnost promijene luke polaska [Sali] po prethodnom dogovoru [naprimjer Zadar, Sukošan].",

    Yes: "Da",
    No: "Ne",

    ProgramDesc: "Program je namijenjen za {0} osoba max i traje od {1}.",

    Day: "Dan",
    IncludedInPrice: "U CIJENU JE UKLJUČENO:",

    NotIncludedInPrice: "NIJE UKLJUČENO U CIJENU:",

    FullUseBoat: "Korištenje broda",

    SkipperExp: "Troškovi skipera",

    VhcDscGps: "VHC, DSC, Color GPS plotter, sonder",
    OutEngine: "Vanbrodski motor 10 ks",
    KitchenUts: "Posuđe",
    FinCleaning: "Završno čiščenje",
    Tax: "Porez",
    Insurance: "Osiguranje plovila i ljudi",
    FuelCost: "Troškovi goriva",
    FoodDrinkExp: "Troškovi hrane i pića (25 EUR / Dan / Osoba)",
    SpecialOfferCruising: "SPECIJALNA PONUDA KRSTARENJE - 400 EUR / Dan",
    KornatiRestaurantExp: "Troškovi hrane (meso/riba) i pića u restoranu na otoku Kornat",
    EntranceExp: "Troškovi ulaznica za PP Telaščica i NP Kornati",

    MultidayCruisingTitle: "Višednevna krstarenja",
    MultidayCruisingText: "Program je namijenjen svim onima koji uistinu žele upoznati čudesnu Ijepotu Dalmacije, njene bisere (otoke, uvale, plaže, žale i doživjeti nezaboravno krstarenje. Vi određujete destinacije i način kako ćete provesti svoj odmor dok krstareća brzina broda omogućuje svakodnevni odlazak na brojne destinacje i povrataku matičnu luku Sali sa osiguranim smještajem u apartmanima. Svrha programa je da svakim danom otkrivate nove Ijepote Jadrana uz zabavu, kupanje i morske specijalitete te komforni smještaj u apartmanima.",
    AccomodationAppartment: "Smještaj i noćenje u apartmanima",

    BedAndTowels: "Posteljina i ručnici",
    AdditionalOffer: "DODATNA PONUDA: Spremanje morskih specijaliteta na brodu sa konzumacijom pića.",
    ProgramDescShort: "Program je namijenjen za {0} osoba max",


    DailyCruising: "Dnevna krstarenja",

    MultidayCruising: "Višednevna krstarenja",

    DailyFishing: "Dnevni ribolov",

    EveningFishing: "Večernji ribolov",

    MultidayFishing: "Višednevni ribolov",

    FishingPlus: "Ribolov plus",

    Boat: "Brod",

    Port: "Luka polaska",

    Apartments: "Apartmani",

    Contact: "Kontakt",

    Gallery: "Galerija slika",

    AboutUs: "O Nama",

    HowToFindUs: "Gdje smo:",

    ContactOwner: "Kako kontaktirati direktno vlasnika:",


    ApartmentsDistance: "Udaljenosti",

    ApartmentsOffer: "Sadržaj",

    ApartmentsPictures: "Slike objekta",

    ApartmentsObjectDesc: "Opis objekta",

    ApartmentsApartments: "Apartmani",

    ApartmentsApartment: "Apartman",

    ApartmentsPriceADay: "Cijena na dan",


    ApartmentsSubtitle: "Apartmani se mogu iznajmiti neovisno o ponudenim programima",


    Owner: "Vlasnik",

    HowToCome: "Kako doci u Sali?",
    GalleryFishing: "Ribolov",

    GalleryGrill: "Hrana",

    GalleryCruising: "Krstarenje",

    GalleryDiving: "Ronjenje",

    HouseDesc: "Kuća se nalazi u mjestu Sali na Dugom otoku, 30m od mora na lijepom i mirnom mjestu. Udaljenost apartmana od plaže kristalno bistrog mora iznosi 150m. U blizini kuće gosti imaju pristup tržnici, pošti, doktoru i caffe-u.",


    DailyFishingTitle: "Dnevni Ribolov",
    DailyFishingText: "Destinacija ribolova je dugootočko-kornatski arhipelag, a na raspolaganju vam stoje razne ribolovne tehnike (savjetujemo kombinaciju parangala, tunje i big game ribolova). Program je namijenjen za najviše 3 osobe, a traje između 09:00 i 17:00 sati.",

    EveningFishingTitle: "Večernji Ribolov",
    EveningFishingText: "Polazak u kasnim popodnevnim satima iz Iuke Sali na Dugom Otoku. Predvečernji ribolov i noćni Iov Iiganja te povratak u Iuku u kasne noćne sate. Prema želji gostiju spremanje morskih specijaliteta od ulova odmah na brodu ili po dogovoru.",

    MultidayFishingTitle: "Višednevni Ribolov",
    MultidayFishingText: "Namijenjen je zaljubljenicima u ribolov i svima onima koji žele osjetiti punu draž ribolova. Budući da nam na raspolaganju stoji više dana, rezultat će biti odličan a sve tehnike ribolova doći će do punog izražaja.",

    FishingPlusTitle: "Ribolov Plus",

    FishingPlusText: " 7 NOĆI POLUPANSION: <br/>Apartmanski smještaj sa ručkom<br/> (razni riblji specijaliteti uz domaće vino, maslinovo ulje i povrće, 25 EUR / Dan / Osoba)<br/>2 IZLETA U RIBOLOV PARANGALOM: <br/>Kombinacija više vrsta različitih pomagala. Poslijepodne - noć.<br/>2 IZLETA U LIGNJOLOV: <br/>Ako nije sezona liganja slijedi bilo koji drugi oblik ribolova. Poslijepodne - noć.<br/>2 BIG GAME RIBOLOVNA IZLETA: Jutro - poslijepodne.",
    BoatTitle: "Brod",

    BoatText: "Jeonneou Merry Fisher 695 je motorni brod idealan za krstarenja i ribolov Zahvaljujući prostranom i širokom kokpitu ribolov je vrlo praktičan a krstarenje vrlo komfortno. Dizel motor od 160 ks osigurava siguran rad uz krstareču brzinu od 23 čvora. Brod je opremljen najsuvremenijom navigcijskom opremom (oolor GPS ploter, VHF DSC oolor fishfinder-sonder kompas) kao i brodskom opremom (el. vitlo, WC, tuš na krmenoj platformi, HI FI, salon, ležajevi, kuhinja , pomoćni motor 10 ks) koja osigurava sigurnost i komfort za 6 osoba i posadu na brodu.",

    PortTitle: "Luka Polaska",
    PortText: "Luka polaska je ribarsko turistička luka Sali, smještena na samome jugu Dugog Otoka čiji su krajevi udaljeni svega nekoliko stotina metara od otoka Kornata.",

    PortText2: "Sali je glavno i najveće naselje na Dugom otoku. lme Sali je dobilo po nekadašnjim solanama, a pod tim imenom prvi put se spominje 1105. g0dine. Smješten0 na sjeveroistoćnoj obali otoka, bogate tisućugodišnje ribarske tradicije (prvi pisani dokument datira iz razdoblja oko 990. godine govori o ribolovu i nastambama u obližnjoj Telaščici). U Salima je razvijeno ribarstvo , poljodjelstvo (maslinarstvo, vinogradarstvo) turizam i manje privatne djelatnosti. Bitno je napomenuti da na cijelom otoku nema tvornica odnosno zgađivača okoliša. U mjestu Sali postoji nekoliko trgovina mješovite robe, pekara, restauranta, konoba i barova dok od društvenih djelatnosti ambulanta, pošta, policija, lučka ispostava i knjižnica. Blizina Nacionalnom Parku Kornati i Parku Prirode Telašcica, Sali (čine odskočnom daskom-predvorjem tih dokazanih jadranskih ljepota, pa mjesto ima sve preduvjete za razvoj izletničkog, nautičkog podvodnog, sportskog i drugih vidova turizma.",

    ApartmentsTitle: "Apartmani",
    ApartmentsText: "Diving",

    ContactTitle: "Diving",
    ContactText: "Imate li bilo kakva pitanja, sve informacije i rezervacije možete dobiti izravno od vlasnika.",


    AboutUsTitle: "O Nama",
    AboutUsText: "Tome je obiteljski obrt koji se bavi turizmom, ribarstvom, prijevozom putnika. Osnivač obrta i voditelj poslovanja je gosp. Tomislav Čarić koji je ujedno vaš domaćin, skiper i voditelj ribolova. Potomak je stare ribarske obitelji Aunedi pa je tako i sam cijelo svoje djetinjstvo proveo na moru, u brodu i ribolovu. Ljubav prema moru nastavlja ploveći svim morima i raznim vrstama brodova. Sa navigacijskim stažom i iskustvom u plovidbi stječe zvanje prvog časnika palube i zapovjednika duge plovidbe. Znanje stečeno na moru i ljubav prema ribolovu uvijek će nesebično i rado podijeliti sa svojim gostima.",

    Price: "Cijena",
    FishingEquipment: "Ribolovna oprema",

    FishingLicense: "Ribolovne dozvole",

    FishingBaits: "Mamci (srdele, lignje, morski crvi, školjke, i razni umjetni mamaci)",

    EveningFishingText2: "Destinacija ribolova je kornatski arhipelag a na raspolaganju vam stoje razne ribolovne tehnike (savjetujemo parangal, udičarenje i big game ribolov). Program je namijenjen za najviée 3 osobe i traje između 18:00 i 24:00 sata.",

    ProgramDescShortExt: "Ovaj program je namijenjen za {0} osobe max i traje u skladu sa prethodnim dogovorom.",

    MultidayFishingText2: "Uz svakodnevni ribolov i morske delicije osiguran je i smještaj u apartmanima radi što većeg komforta gostiju. Luka Sali je u sklopu kornatskog arhipelaga i pruža mogućnost svih vrsta ribolova ukljućujući i Big Game Fishing.",
    FishingPlusPackagePriceText: "CIJENA PAKETA 800 EUR PO OSOBI<br/>Minimalni broj osoba 2 te max 4<br/>Dostupno od 01.04.-31.12 svake godine.",
    FishingPlusText2: " 7 NOĆI POLUPANSION: <br/>Apartmanski smještaj sa ručkom<br/> (razni riblji specijaliteti uz domaće vino, maslinovo ulje i povrće, 25 EUR / Dan / Osoba)<br/>5 IZLETA U LIGNJOLOV: <br/>Moguće kombinirati sa parangalom.<br/>1 IZLET U PN TELAŠČICA I NACIONALNI PARK KORNATI:<br/> Jutro - poslijepodne.",
    FishingPlusPackagePriceText2: "CIJENA PAKETA 600 EUR PO OSOBI<br/>Minimalni broj osoba je 3 a max 5<br/>Dostupno od 05.09.-31.12 svake godine.",
    ResidencePermit: " BORAVIŠNA PRISTOJBA <br/>1 EUR po osobi dnevno<br/>0.5 EUR po djeci 12-18 g. dnevno<br/>0 EUR po djeci ispod 12 g.",
    ApartmentAdvantage: " POGODNOST (za apartmanske goste) <br/>-popust na izlete i ribolovne izlete od 15%<br/>-besplatna riblja večra i vino za sve goste koji iznajme apartman dulje od 7 dana u svibnju, lipnju ili rujnu<br/>-besplatna riblja večera i vino za sve goste koji iznajme apartman dulje od 14 dana u srpnju i kolovozu<br/>-slobodno raspolaganje sa roštiljem<br/>-mogučnost iznajmljivanje plastičnog broda dužine 4.5m sa vanjskim motorom snage 9.8 ks",
    Apartment4Text2: "U apartmanu (sobi) se nalazi soba (2 krevata), stol sa dvije sjedalice u kupatilo.",
    Apartment3Text2: "Nalazi se u prizemlju (bočno) i ima spavaću sobu (2 kreveta), kuhinju sa dnevnim boravkom (1 krevet moguć), kupatilo.",
    Apartment2Text2: "Nalazi se na katu ima jednu spavaću sobu (2 kreveta), spavaću sobu (2 kreveta), spavaću sobu (1 krevet),kupaonu, veliku terasu i kuhinju sa dnevnim boravkom.",
    Apartment1Text2: "Nalazi se u prizemlju i ima spavaću sobu (2 kreveta), spavaću sobu (2 kreveta), dnevni boravak (1 krevet moguće ), kuhinja, kupaona, wc, velika terasa sa vanjskim tušem.",
    ContactOwnerData: "capt. Tomislav Caric. <br/>Sali 23281, Dugi otok, Hrvatska<br/>tel. +385 (0)23 377 489<br/>gsm. +385 (0)98 629 353<br/>e-mail. tomislav.caric2@gmail.com",


    ApartmentsSea: "More",
    ApartmentsBeach: "Plaža",
    ApartmentsBerth: "Centar",
    ApartmentsAmbulance: "Ambulanta",


    ApartmentsExcursions: "Izleti",
    ApartmentsFishing: "Ribolov",
    ApartmentsDiving: "Ronjenje",
    ApartmentsPets: "Ljubimci",


    ApartmentsBedsNumber: "Broj kreveta",
    ApartmentsBarbecue: "Roštilj",
    ApartmentsSize: "Veličina [m2]",
    ApartmentsTerrace: "Terasa",
    ApartmentsCategory: "Kategorija",
    ApartmentsRoomsNumber: "Broj soba",
    ApartmentsSeaView: "Pogled na more",
    ApartmentsSatTV: "Sat TV LCD",
    ApartmentsBathroom: "Kupatilo",
    ApartmentsAirCondition: "Klimatizacija",
    ApartmentsWashmachine: "Perilica za robu",
    ApartmentsDishWasher: "Perilica suđa",
    ApartmentsWIFI: "WIFI",
    ApartmentsFrigo: "Frigo",



    ContactaByCarTitle: "Autom",
    ContactaByCarText: "Iz Zadra trajektom Zadar-Brbinj (cca 1h 30min). Nakon iskrcaja u luci Brbinj na Dugom otoku slijedi vožnja cestom 20 km (cca 25 min) do mjesta Sali gdje vas čeka vlasnik.",
    ContactaWithoutCarTitle: "Bez auta",
    ContactaWithoutCarText: "Iz Zadra brodom Zadar-Sali (cca 1h 15min) ili hidrogliserom Zadar-Sali (cca 35min) gdje vas čeka vlasnik.",
    ContactaSpecOfferTitle: "Posebna ponuda",
    ContactaSpecOfferText: "Posebna ponuda prijevoz brodom vlasnika, Zadar-Sali (cca 60 min) po prethodnom dogovoru",




    BoatLength: "Duljina",
    BoatBeam: "Širina",
    BoatEngine: "Motor",
    BoatDraught: "Gaz",
    BoatBeds: "Ležajevi",
    BoatWC: "WC",
    BoatFuel: "Gorivo",
    BoatWater: "Voda",
    BoatRadar: "Radar",


    FishingWeekPlus: "SEDMICA RIBOLOV",
    SquidFishingWeek: "SEDMICA LIGNJOLOV"

}

export default Hr;