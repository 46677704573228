import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SRLWrapper } from "simple-react-lightbox";
const Apartments = ({ data }) => {
    const { translation } = data;
    return (
        <SRLWrapper>
            <div>
                <div className="welcomeDiv">
                    <h2>
                        {translation.ApartmentsTitle}
                    </h2>
                </div>


                <div className="welcomeDiv">
                    {translation.ApartmentsSubtitle}
                </div>


                <div className="subHeader">
                    <div>{translation.ApartmentsDistance} (m):</div>
                </div>

                <div className="row">
                    <div className="col-md-2">
                        <b>
                            {translation.ApartmentsSea}
                        </b> <br />
                        30
                    </div>
                    <div className="col-md-2">
                        <b>
                            {translation.ApartmentsBeach}
                        </b><br />
                        150
                    </div>
                    <div className="col-md-2">
                        <b>
                            {translation.ApartmentsBerth}
                        </b><br />
                        30
                    </div>
                    <div className="col-md-2">
                        <b>
                            Restaurant
                        </b><br />
                        100
                    </div>
                    <div className="col-md-2">
                        <b>
                            Market
                        </b><br />
                        40
                    </div>
                    <div className="col-md-2">
                        <b>
                            {translation.ApartmentsAmbulance}
                        </b><br />
                        120
                    </div>
                </div>

                <div className="subHeader">
                    {translation.ApartmentsOffer}:
                </div>

                <div className="row">
                    <div className="col-md-3"> <b> {translation.ApartmentsExcursions} </b><br />{translation.Yes}</div>
                    <div className="col-md-3"><b> {translation.ApartmentsFishing} </b><br />{translation.Yes}</div>
                    <div className="col-md-3"><b>{translation.ApartmentsDiving} </b><br />{translation.Yes}</div>
                    <div className="col-md-3"><b>Rent a boat </b><br />{translation.Yes}</div>
                </div>

                <div className="row">
                    <div className="col-md-3"><b>{translation.ApartmentsBerth} </b><br />{translation.Yes}</div>
                    <div className="col-md-3"><b>Parking </b><br />{translation.Yes}</div>
                    <div className="col-md-3"><b>{translation.ApartmentsPets} </b><br /> {translation.No}</div>
                    <div className="col-md-3"></div>
                </div>

                <div className="subHeader">
                    {translation.ApartmentsPictures}:
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <a href="/grafika/apartmani/kuca1.jpg" alt='House'>
                                <img src="/grafika/apartmani/kuca1.jpg" alt='House' className="img-thumbnail" />
                            </a>
                            <a href="/grafika/apartmani/kuca2.jpg" alt='House'>
                                <img src="/grafika/apartmani/kuca2.jpg" alt='House' className="img-thumbnail" />
                            </a>
                            <a href="/grafika/apartmani/kuca3.jpg" alt='House'>
                                <img src="/grafika/apartmani/kuca3.jpg" alt='House' className="img-thumbnail" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="subHeader">
                    {translation.ApartmentsObjectDesc}:
                </div>

                <div className="regulaMiddleRow">
                    <p>
                        {translation.HouseDesc}
                    </p>
                </div>


                <div className="subHeader">
                    <h4> {translation.ApartmentsApartments}</h4>
                </div>


                <div className="subHeaderGreen">
                    {translation.ApartmentsApartment} 1:
                </div>

                <div className="img-thumbnailContainer">

                    <div className="row">

                        <div className="col-md-6">

                            <div className="img-thumbnailContainer">
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsBedsNumber}:</b> 4+1</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsBarbecue}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsSize}:</b> 70</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsTerrace}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsCategory}:</b> ***</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsRoomsNumber}:</b> 2</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsSatTV}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsBathroom}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsDishWasher}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsAirCondition}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsWIFI}:</b> {translation.Yes}</div>
                            </div>

                            <div className="img-thumbnailContainer t10">
                                <b>   {translation.ApartmentsPriceADay}</b>
                            </div>
                            <div className="img-thumbnailContainer">
                                <img src="/grafika/apartmani/app1prices.png" className="appartPriceImagesContainer" alt="tome" />
                            </div>

                        </div>

                        <div className="col-md-6">
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman_1.jpg" >
                                <img src="/grafika/apartmani/apartman_1.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman_1_2.jpg" data-lightbox="apartman_1">
                                <img src="/grafika/apartmani/apartman_1_2.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman_1_3.jpg" data-lightbox="apartman_1">
                                <img src="/grafika/apartmani/apartman_1_3.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman_1_4.jpg" data-lightbox="apartman_1">
                                <img src="/grafika/apartmani/apartman_1_4.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman_1_5.jpg" data-lightbox="apartman_1">
                                <img src="/grafika/apartmani/apartman_1_5.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman_1_6.jpg" data-lightbox="apartman_1">
                                <img src="/grafika/apartmani/apartman_1_6.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman_1_7.jpg" data-lightbox="apartman_1">
                                <img src="/grafika/apartmani/apartman_1_7.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman_1_8.jpg" data-lightbox="apartman_1">
                                <img src="/grafika/apartmani/apartman_1_8.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                        </div>

                    </div>

                </div>

                <div className="regulaMiddleRow">
                    <p className="leftAlignParagraph">
                        {translation.Apartment1Text2}
                    </p>
                </div>

                <div className="subHeaderGreen">
                    {translation.ApartmentsApartment} 3:
                </div>

                <div className="img-thumbnailContainer">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="img-thumbnailContainer">
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsBedsNumber}:</b> 2+1</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsBarbecue}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsSize}:</b> 34</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsTerrace}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsCategory}:</b> ***</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsRoomsNumber}:</b> 2</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsSeaView}:</b> {translation.No}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsSatTV.replace("LCD", "")}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsBathroom}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsWIFI}:</b> {translation.Yes}</div>
                            </div>

                            <div className="img-thumbnailContainer t10">
                                <b>   {translation.ApartmentsPriceADay}</b>
                            </div>
                            <div className="img-thumbnailContainer">
                                <img src="/grafika/apartmani/app3prices.png" className="appartPriceImagesContainer" alt="tome" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman31.jpg" data-lightbox="apartman_3">
                                <img src="/grafika/apartmani/apartman31.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman32.jpg" data-lightbox="apartman_3">
                                <img src="/grafika/apartmani/apartman32.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman33.jpg" data-lightbox="apartman_3">
                                <img src="/grafika/apartmani/apartman33.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman34.jpg" data-lightbox="apartman_3">
                                <img src="/grafika/apartmani/apartman34.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman35.jpg" data-lightbox="apartman_3">
                                <img src="/grafika/apartmani/apartman35.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                            <a className="img-thumbnail" href="/grafika/apartmani/apartman36.jpg" data-lightbox="apartman_3">
                                <img src="/grafika/apartmani/apartman36.jpg" className="img-thumbnail" alt="tome" />
                            </a>
                        </div>

                    </div>
                </div>

                <div className="regulaMiddleRow">
                    <p className="leftAlignParagraph">
                        {translation.Apartment3Text2}
                    </p>
                </div>


                <div className="subHeaderGreen">
                    {translation.ApartmentsApartment} 4:
                </div>

                <div className="img-thumbnailContainer">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="img-thumbnailContainer">
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsBedsNumber}:</b> 2</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsBarbecue}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsSize}:</b> 18</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsTerrace}:</b> {translation.No}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsCategory}:</b> ***</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsRoomsNumber}:</b> 1</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsSeaView}:</b> {translation.No}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsSatTV.replace("Sat", "")}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsBathroom}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsAirCondition}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsWIFI}:</b> {translation.Yes}</div>
                                <div className="img-thumbnailContainer"><b>{translation.ApartmentsFrigo}:</b> {translation.Yes}</div>
                            </div>

                            <div className="img-thumbnailContainer t10">
                                <b>   {translation.ApartmentsPriceADay}</b>
                            </div>
                            <div className="img-thumbnailContainer">
                                <img src="/grafika/apartmani/app4prices.png" className="appartPriceImagesContainer" alt="tome" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <a className="mw-100" href="/grafika/apartmani/a4001.jpg" data-lightbox="apartman_4">
                                <img src="/grafika/apartmani/a4001.jpg" className="img-thumbnail mw-100" alt="tome" />
                            </a>
                            <a className="mw-100" href="/grafika/apartmani/a4002.jpg" data-lightbox="apartman_4">
                                <img src="/grafika/apartmani/a4002.jpg" className="img-thumbnail mw-100" alt="tome" />
                            </a>
                            <a className="mw-100" href="/grafika/apartmani/a4003.jpg" data-lightbox="apartman_4">
                                <img src="/grafika/apartmani/a4003.jpg" className="img-thumbnail mw-100" alt="tome" />
                            </a>
                            <a className="mw-100" href="/grafika/apartmani/a4004.jpg" data-lightbox="apartman_4">
                                <img src="/grafika/apartmani/a4004.jpg" className="img-thumbnail mw-100" alt="tome" />
                            </a>

                            <a className="mw-100" href="/grafika/apartmani/a4005.jpg" data-lightbox="apartman_4">
                                <img src="/grafika/apartmani/a4005.jpg" className="img-thumbnail mw-100" alt="tome" />
                            </a>

                            <a className="mw-100" href="/grafika/apartmani/a4006.jpg" data-lightbox="apartman_4">
                                <img src="/grafika/apartmani/a4006.jpg" className="img-thumbnail mw-100" alt="tome" />
                            </a>

                        </div>
                    </div>
                </div>

                <div className="regulaMiddleRow">
                    <p className="leftAlignParagraph">
                        {translation.Apartment4Text2}
                    </p>
                </div>

                <div className="rulerLineGreen t20"></div>
                <div className="rulerLineYellow"></div>

                <div className="regulaMiddleRow">
                    <img src="/grafika/apartmani/kamin.jpg" className="img-thumbnail" alt="tome" />
                    <img src="/grafika/apartmani/kamin2.jpg" className="img-thumbnail" alt="tome" />
                </div>

                <div className="rulerLineGreen t20"></div>
                <div className="rulerLineYellow"></div>

                <div className="regulaMiddleRow">
                    <p className="leftAlignParagraph" dangerouslySetInnerHTML={{ __html: translation.ResidencePermit }} />
                </div>
            </div>
        </SRLWrapper>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(Apartments));