const En = {
    Home: "Home",
    HomeTitle: "Welcome !",
    HomeText: "Discover the spirit of the Mediteranian as it used to be. Visit the most beautiful destinations in the Adriatic. Enjoy the unique cruise through the Kornati archipelago, feel the enjoyment of fishing and rest in the last port preceding the stone beauty of Kornati islands.",
    DailyCruisingTitle: "Daily cruising",
    DailyCruisingText: "The program is suitable for all who are unable to stay longer at sea and for guests in hotel and apartment accommodation who would like to enjoy our beautiful islands, unspoiled nature, secluded beaches, crystal waters and the diverse seafood specials.",
    ADVANTAGE: "ADVANTAGE: It is possible to change the port of departure [Sali] according to a previous agreement [e.g. Zadar, Sukosan].",
    Yes: "Yes",
    No: "No",
    ProgramDesc: "This program is intended for {0} persons max and lasts between {1}.",
    Day: "Day",
    IncludedInPrice: "INCLUDED IN PRICE:",
    NotIncludedInPrice: "NOT INCLUDED IN PRICE:",
    FullUseBoat: "Full use of boat and equipment",
    SkipperExp: "Expenses of a skipper",
    VhcDscGps: "VHC, DSC, Color GPS chart plotter",
    OutEngine: "Outboard engine 10 hp",
    KitchenUts: "Kitchen utensils",
    FinCleaning: "Final cleaning",
    Tax: "Tax",
    Insurance: "Insurance - boat and persons",
    FuelCost: "Costs of fuel",
    FoodDrinkExp: "Expenses of food and drink (25 EUR / Day / Person)",
    SpecialOfferCruising: "SPECIAL OFFER CRUISING - 400 EUR / Day",
    KornatiRestaurantExp: "Expenses of food (fish/meat) and drink in restaurant on a Kornat island",
    EntranceExp: "Expenses of entrance (tickets) in Park of Nature Telascica and National Park Kornati",
    MultidayCruisingTitle: "Multiday Cruising",
    MultidayCruisingText: "This program is suitable for all who want to experience the astonishing beauty of Dalmatia-its pearls, islands, coves, beaches and to undertake an unforgettable cruise. You determine the destinations and chose the ways how to spend your vacation while the ships speed enables us to reach numerous destinations and to  to the port of departure in Sali where apartment accomodation is available. The aim of the program is to let you discover the new beauties of the Adriatic along with entertainment swimming, seafood specials and comfortable accommodation.",
    AccomodationAppartment: "Acomodation in apartment",
    BedAndTowels: "Bed linen and towels",
    AdditionalOffer: "ADDITIONAL OFFER: Preparing seafood and speials with drink.",
    ProgramDescShort: "This program is intended for {0} persons max",


    ProgramDescShortExt: "This program is intended for {0} persons max and lasts according to a previous agreement",


    DailyCruising: "Daily Cruising",


    MultidayCruising:
        "Multiday Cruising",


    DailyFishing:
        "Daily Fishing",


    EveningFishing
        : "Evening Fishing",


    MultidayFishing: "Multiday Fishing",


    FishingPlus: "Fishing Plus",


    Boat: "Boat",


    Port: "Base Port",


    Apartments: "Apartments",


    Contact: "Contact",


    Gallery: "Picture Gallery",


    AboutUs: "About Us",



    HowToFindUs: "How to find us:",


    ContactOwner: "How to directly contact the owner:",


    ApartmentsSubtitle: "Apartmens can be rent independent of programs",

    ApartmentsDistance: "Distance",

    ApartmentsOffer: "Offer",

    ApartmentsPictures: "Object pictures",

    ApartmentsObjectDesc: "Object description",

    ApartmentsApartments: "Apartments",

    ApartmentsApartment: "Apartment",

    ApartmentsPriceADay: "Price a day",

    Owner: "Owner",

    HowToCome: "How to come in Sali?",


    GalleryFishing: "Fishing",

    GalleryGrill: "Grill",

    GalleryCruising: "Cruising",

    GalleryDiving: "Diving",

    HouseDesc: "House is situated in center of port Sali on Dugi otok 30m from sea on beautiful and calm place. Distance from apartments to beach is 150m where sea is crystal clear. In vincinity of house tourist have on disposal market, post-office, doctor, restaurant and caffe.",


    DailyFishingTitle: "Daily Fishing",

    EveningFishingTitle: "Evening Fishing",
    MultidayFishingTitle: "Multiday Fishing",

    FishingPlusTitle: "Fishing Plus",

    PortTitle: "Base Port",

    ApartmentsTitle: "Apartments",

    AboutUsTitle: "About Us",

    DailyFishingText: "Destination for the fishing trips is the Dugi otok - Kornati archipelago. You can use various fishing techniques (we recomend the combination of long line, fishing line or big game fishing). this program is intended for 3 persons max. and lasts between 09:00 and 17:00 hours.",


    FishingEquipment: "Fishing equipment",
    FishingLicense: "Fishing license",
    FishingBaits: "Baits(sardines,squids,sea worms,mussels,needlefish and imitations-rapalas)",
    EveningFishingText: "Departure is in the late afternoon hours frcm the port of Sali on the Dugi Otok. Evening fishing and calamari fishing takes place during the night and we  to the port late in the evening. According to guests‘ wishes, we prepare the seafood specials immediately at the ship or later according to an agreement.",
    EveningFishingText2: "Destination for the fishing trips is the Dugi otok-Kornati archipelago. You can use various fishing techniques (we recommend the combination of long line, fishing line and calamari fishing). This program is intended for 3 persons max. and lasts between 18:00 and 24:00 hours.",

    MultidayFishingText: "lntended for the true fishing enthusiasts and those who want to experience fishing to its fullest. Since we spend several days fishing, the results will be outstanding and all the fishing techniques will be represented.",

    MultidayFishingText2: "Along with everyday fishing and seafood delights, we have apartment accommodation available for our guests‘ greater comfort. The port of Sali is a part of the Kornati archipelago and this enables all types of fishing including the Big Game Fishing.",
    FishingPlusText: ` 7 NIGHTS WITH HALF BOARD: <br/>
                    Apartment accomodation with lunch <br/> (diverse ﬁsh specials with home made vine, olive oil and vegetables, 25 EUR / Day / Person) <br/>
                    2 FISHING TRIPS WITH LONG LINES: <br/>
                    Combination of different types of long lines.Afternoon - night.<br/>
                        2 SQUID / FISHING TRIPS: <br/>
                    If it is out of squid season any other form of fishing.Afternoon - night.<br/>
                        2 BIG GAME FISHING: Morning - afternoon.`,


    FishingPlusPackagePriceText: `PACKAGE PRICE 800 EUR ON PARTICIPANT<BR/>
                Minimal number of participants is 2 and max 4 <br/>
                    Availabile from 01.04.- 31.12 every year.`,


    FishingPlusText2: ` 7 NIGHTS WITH HALF BOARD: <br/>
        Apartment accomodation with lunch <br/> (diverse ﬁsh specials with home made vine, olive oil and vegetables, 25 EUR / Day / Person) <br/>
        5 SQUID FISHING TRIPS: <br/>
        Can be combined with long line Afternoon - night.<br/>
            1 TRIP IN PN TELASCICA AND NATIONAL PARK KORNATI: <br/>
        Morning - afternoon.`,


    FishingPlusPackagePriceText2: `PACKAGE PRICE 600 EUR ON PARTICIPANT<BR/>
        Minimal number of participants is 3 and max 5 <br/>
            Availabile from 05.09.- 31.12 every year.`,

    BoatTitle: "Boat",

    BoatText: "Jeanneau Merry Fisher 695 is motor boat ideal for cruising and fishing. Thanks to spacious and large cockpit fishing is very practical and cruising is very comfort. Disel motor of 160 hp ensures safe work with cruising speed up to 24 knots. Boat is equiped with optimal navigation eguipment (color GPS, chart plotter, VHF DSC, color fishfinder/sounder, compass) as boat equipment(el. winch, WC, shower on bathing platform,HI-FI,saloon,beds, kitchen, additional engine) which ensures safety and comfort for 6 persons and crew.",

    PortText: "Base port is fisherman and turistical port Sali, situated on southern part at Dugi otok whose southern ends of island have distance just couple houndred metres from Kornati islands.",

    PortText2: "Sali is main and largest settlement on Dugi otok. The name Sali originates from salt-works, and it is mentioned for the first time in 1105. Situated on the north east coast of the southern part of island,with a thousand year old fishing tradition (the first written document in Croatia about fishing in the nearly Telasoioa dates from 990). Sali has developed fishing, agriculture (olives, wine),tourism and other minor private jobs. On Dugi otok and nearby area of 150 islands there is no fabrios or any other polution sources just pure untouched nature in symbiosis with cristal sea. In port of Sali there is bakery, saveral grocery shops, restaurants, coffe shops, bars, ambulanoe, post office, library and harbour master offioe. Situated nearby National Park Kornati and Park of Nature Telasoioa Sali is an ante-room to all of the well known Adriatic beauties, and therefore it has all the requirements for the development of all aspects of tourism - oruising, sailing, fishing, diving, excursions and sports.",


    ApartmentsText: "Diving",

    ContactTitle: "Diving",
    ContactText: "If you have any questions, all information's and reservations you can get directly from owner.",

    AboutUsText: "Tome is a family business dealing with tourism, fishing and passenger transport. The founder and the manager of the business is Mr.Tomislav Caric (Tome) who is also your host, skipper and the fishing trip guide. He is a descendant of an old fishing family Aunedi and has spent his childhood by the sea, sailing and fishing. His love and respect for the sea continued on and he had sailed through all the seas on numerous vessels. With his navigation and sailing skills he becomes the chief officer of the deck and sailing commander. He will gladly share with his guests all his knowledge obtained at the sea and his love for fishing.",
    Price: "Price",
    ResidencePermit: ` RESIDENCE PERMIT <br/>
        1 EUR per person a day <br/>
            0.5 EUR per kids 12 - 18 y per day <br/>
                0 EUR per kids under 12 y.`,


    ApartmentAdvantage: ` ADVANTAGE (for the apartments guests) <br/>
        -discounts for cruising and fishing trips of 15 % <br/>
            - free fish dinner and vine for all guests who rent more than 7 days in May, June or September <br/>
                -free fish dinner and vine for all guests who rent more than 14 days in July and August <br/>
                    -possibility to use grill by own <br/>
                        -possibility to  rent a plastic boat 4.5m with outboard engine 9.8 hp`,
    Apartment4Text2: "In apartment (room) there are bedroom (2 beds), table with two chairs and bathroom ",
    Apartment3Text2: "In downstairs (side) apartment there are bedroom (2 beds), kitchen with living room (possibility of 1 bed), bathroom ",
    Apartment2Text2: "In upstairs apartment there are bedroom (2 beds), bedroom (2 beds), bedroom (1 bed), bathroom, balcony, large terrace and kitchen with living room.",
    Apartment1Text2: "In downstairs apartment there are bedroom (2 beds), bedroom (2 beds), living room (possibility of 1 bed), kitchen, bathroom, toilet and big terrace with outside shower.",
    ContactOwnerData: `capt. Tomislav Caric. <br/>
        Sali 23281, Dugi otok, Hrvatska <br/>
            tel: + 385(0)23 377 489 <br/>
                gsm: + 385(0)98 629 353 <br/>
                    email: tomislav.caric2@gmail.com`,
    ApartmentsSea: "Sea",
    ApartmentsBeach: "Beach",
    ApartmentsBerth: "Berth",
    ApartmentsAmbulance: "Ambulance",
    ApartmentsExcursions: "Excursions",
    ApartmentsFishing: "Fishing",
    ApartmentsDiving: "Diving",
    ApartmentsPets: "Pets",
    ApartmentsBedsNumber: "Number of beds",
    ApartmentsBarbecue: "Barbecue",
    ApartmentsSize: "Size [m2]",
    ApartmentsTerrace: "Terrace",
    ApartmentsCategory: "Category",
    ApartmentsRoomsNumber: "Number of rooms",
    ApartmentsSeaView: "Sea view",
    ApartmentsSatTV:
        "Sat TV LCD",
    ApartmentsBathroom:
        "Bathroom",
    ApartmentsAirCondition:
        "Air-Condition",
    ApartmentsWashmachine:
        "Wash machine",
    ApartmentsDishWasher: "Dish washer",
    ApartmentsWIFI:
        "WIFI",
    ApartmentsFrigo:
        "Frigo",
    ContactaByCarTitle: "By car",
    ContactaByCarText:
        "There is ferry from Zadar-Brbinj (cca 1h 30min). After landing in port Brbinj on Dugi otok, you have to drive about 20 km (cca 25 min) to Sali where the owner will wait for you.",
    ContactaWithoutCarTitle:
        "Without car",
    ContactaWithoutCarText:
        "You can take a ship from Zadar-Sali (cca 1h 15min) or fast foilcraft Zadar-Sali (cca 35min), where owner of the apartments will wait for you.",
    ContactaSpecOfferTitle:
        "Special offer",
    ContactaSpecOfferText: "Transport by owner Zadar-Sali (cca 60min) according to previous arrangement",
    BoatLength: "Length",
    BoatBeam: "Beam",
    BoatEngine: "Engine",
    BoatDraught: "Draught",
    BoatBeds: "Beds",
    BoatWC: "WC",
    BoatFuel: "Fuel",
    BoatWater: "Water",
    BoatRadar: "Radar",
    FishingWeekPlus: "FISHING WEEK PLUS",
    SquidFishingWeek: "SQUID FISHING WEEK"
}

export default En;