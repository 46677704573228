import { getTranslation } from "../translate";

const initialState = {
    language: 'en',
    translation: getTranslation('en')
}

const store = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LANGUAGE':
            return {
                language: action.payload,
                translation: getTranslation(action.payload || 'en')
            };
        default:
            return state;
    }
}

export default store;