import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { changeLanguage } from "../../actions/language";

const Flags = (props) => {
    return (
        <div className="flagDivContainer d-none d-md-block">
            <div className="flagDiv">
                <button onClick={() => props.changeLanguage('hr')} className="btn btn-link">
                    <img src="/grafika/hr.png" className="flagImg" alt="Hrvatski" title="Hrvatski" />
                </button>
            </div>
            <div className="flagDiv">
                <button onClick={() => props.changeLanguage('it')} className="btn btn-link">
                    <img src="/grafika/it.png" className="flagImg" alt="Italiano" title="Italiano" />
                </button>
            </div>
            <div className="flagDiv">
                <button onClick={() => props.changeLanguage('de')} className="btn btn-link">
                    <img src="/grafika/ger.png" className="flagImg" alt="Deutsch" title="Deutsch" />
                </button>
            </div>
            <div className="flagDiv">
                <button onClick={() => props.changeLanguage('en')} className="btn btn-link">
                    <img src="/grafika/gbr.png" className="flagImg" alt="English" title="English" />
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps, { changeLanguage })(withRouter(Flags));
