import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SRLWrapper } from "simple-react-lightbox";
const Gallery = ({ data }) => {
    const { translation } = data;
    return (
        <SRLWrapper>
            <div>

                <div className="welcomeDiv">
                    <div className="h30w100p">
                        <h3>
                            Sali
                        </h3>
                    </div>
                </div>

                <div className="regulaMiddleRow">

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/1.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/2.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/2.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/a.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/a.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/b.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/b.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/c.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/c.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/d.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/d.jpg" alt="tome" />
                    </a>

                </div>

                <div className="regulaMiddleRow">

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/e.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/e.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/f.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/f.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/h.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/h.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/i.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/i.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/j.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/j.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/k.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/k.jpg" alt="tome" />
                    </a>

                </div>


                <div className="regulaMiddleRow">

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/l.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/l.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/m.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/m.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/n.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/n.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/o.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/o.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/p.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/p.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/r.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/r.jpg" alt="tome" />
                    </a>

                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/sali/s.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/s.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/t.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/t.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/u.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/u.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/v.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/v.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/w.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/w.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/sali/z.jpg" data-lightbox="SaliImages">
                        <img className="w16PrMt5" src="/grafika/slike/sali/male/z.jpg" alt="tome" />
                    </a>
                </div>

                <div className="welcomeDiv">
                    <div className="h30w100p">
                        <h3>
                            Dugi Otok
                        </h3>
                    </div>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/a.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/a.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/b.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/b.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/c.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/c.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/d.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/d.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/e.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/e.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/p.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/p.jpg" alt="tome" />
                    </a>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/f.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/f.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/g.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/g.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/h.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/h.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/i.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/i.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/j.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/j.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/r.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/r.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/k.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/k.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/l.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/l.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/m.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/m.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/n.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/n.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/o.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/o.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/dugi/s.jpg" data-lightbox="DugiOtokImages">
                        <img className="w16PrMt5" src="/grafika/slike/dugi/male/s.jpg" alt="tome" />
                    </a>
                </div>


                <div className="welcomeDiv">
                    <div className="h30w100p">
                        <h3>
                            NP Kornati
                        </h3>
                    </div>
                </div>


                <div className="regulaMiddleRow">

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/a.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/a.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/b.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/b.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/c.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/c.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/d.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/d.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/e.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/e.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/f.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/f.jpg" alt="tome" />
                    </a>


                </div>

                <div className="regulaMiddleRow">

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/g.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/g.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/h.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/h.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/i.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/i.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/j.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/j.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/k.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/k.jpg" alt="tome" />
                    </a>


                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/l.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/l.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/m.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/m.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/n.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/n.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/o.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/o.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/p.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/p.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/r.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/r.jpg" alt="tome" />
                    </a>


                    <a className="w16PrMt5-link" href="/grafika/slike/kornati/s.jpg" data-lightbox="KornatiImages">
                        <img className="w16PrMt5" src="/grafika/slike/kornati/male/s.jpg" alt="tome" />
                    </a>
                </div>



                <div className="welcomeDiv">
                    <div className="h30w100p">
                        <h3>
                            Telascica
                        </h3>
                    </div>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/a.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/a.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/b.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/b.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/c.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/c.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/d.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/d.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/e.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/e.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/f.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/f.jpg" alt="tome" />
                    </a>
                </div>


                <div className="regulaMiddleRow">

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/g.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/g.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/h.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/h.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/i.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/i.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/j.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/j.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/k.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/k.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/l.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/l.jpg" alt="tome" />
                    </a>

                </div>


                <div className="regulaMiddleRow">

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/m.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/m.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/n.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/n.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/o.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/o.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/p.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/p.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/r.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/r.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/telascica/s.jpg" data-lightbox="TelascicaImages">
                        <img className="w16PrMt5" src="/grafika/slike/telascica/male/s.jpg" alt="tome" />
                    </a>
                </div>



                <div className="welcomeDiv">
                    <div className="h30w100p">
                        <h3>
                            {translation.GalleryFishing}
                        </h3>
                    </div>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/a1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/a1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/b1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/b1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/c1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/c1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/d1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/d1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/e1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/e1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/f1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/f1.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/a.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/a.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/b.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/b.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/c.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/c.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/d.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/d.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/e.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/e.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y.jpg" alt="tome" />
                    </a>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/f.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/f.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/g.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/g.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/h.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/h.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/i.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/i.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/j.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/j.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x.jpg" alt="tome" />
                    </a>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/k.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/k.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/l.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/l.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/m.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/m.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/n.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/n.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/o.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/o.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/w.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/w.jpg" alt="tome" />
                    </a>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/p.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/p.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/r.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/r.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/s.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/s.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/t.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/t.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/u.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/u.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/v.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/v.jpg" alt="tome" />
                    </a>
                </div>



                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/p1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/p1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/r1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/r1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/s1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/s1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/t1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/t1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/u1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/u1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/v1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/v1.jpg" alt="tome" />
                    </a>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x2.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x2.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x3.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x3.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x4.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x4.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x5.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x5.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x6.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x6.jpg" alt="tome" />
                    </a>
                </div>



                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x7.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x7.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x8.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x8.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x9.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x9.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x10.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x10.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x11.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x11.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/x12.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/x12.jpg" alt="tome" />
                    </a>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y2.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y2.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y3.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y3.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y4.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y4.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y5.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y5.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y6.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y6.jpg" alt="tome" />
                    </a>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/051.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/051.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/052.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/052.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/053.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/053.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/054.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/054.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/055.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/055.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/056.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/056.jpg" alt="tome" />
                    </a>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/057.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/057.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/059.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/059.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/060.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/060.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/061.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/061.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/062.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/062.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/063.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/063.jpg" alt="tome" />
                    </a>
                </div>



                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y7.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y7.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y8.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y8.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y9.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y9.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y10.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y10.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y11.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y11.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/y12.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/y12.jpg" alt="tome" />
                    </a>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z1.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z1.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z2.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z2.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z3.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z3.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z4.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z4.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z5.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z5.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z6.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z6.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z7.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z7.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z8.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z8.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z9.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z9.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z10.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z10.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z11.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z11.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/z12.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/z12.jpg" alt="tome" />
                    </a>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/071.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/071.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/072.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/072.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/073.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/073.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/074.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/074.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/075.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/075.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/076.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/076.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/077.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/077.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/078.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/078.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/079.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/079.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/080.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/080.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/081.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/081.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/082.jpg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/082.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/tome/083.jpeg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/083.jpeg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/084.jpeg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/084.jpeg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/085.jpeg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/085.jpeg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/086.jpeg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/086.jpeg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/087.jpeg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/087.jpeg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/tome/088.jpeg" data-lightbox="FishingImages">
                        <img className="w16PrMt5" src="/grafika/slike/tome/male/088.jpeg" alt="tome" />
                    </a>
                </div>

                <div className="welcomeDiv">
                    <div className="h30w100p">
                        <h3>
                            {translation.GalleryGrill}
                        </h3>
                    </div>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/grill/a.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/a.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/b.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/b.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/c.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/c.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/d.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/d.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/e.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/e.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/m.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/m.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/grill/g.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/g.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/h.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/h.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/i.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/i.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/j.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/j.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/k.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/k.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/l.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/l.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/grill/t.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/t.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/n.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/n.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/o.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/o.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/p.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/p.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/r.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/r.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/s.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/male/s.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/grill/001.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/001.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/002.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/002.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/003.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/003.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/004.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/004.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/005.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/005.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/006.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/006.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/grill/007.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/007.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/008.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/008.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/009.jpg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/009.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/010.jpeg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/010.jpeg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/011.jpeg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/011.jpeg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/grill/012.jpeg" data-lightbox="GrillImages">
                        <img className="w16PrMt5" src="/grafika/slike/grill/012.jpeg" alt="tome" />
                    </a>
                </div>


                <div className="welcomeDiv">
                    <div className="h30w100p">
                        <h3>
                            {translation.GalleryCruising}
                        </h3>
                    </div>
                </div>


                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/a.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/a.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/b.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/b.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/c.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/c.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/d.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/d.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/e.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/e.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/f.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/f.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/g.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/g.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/h.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/h.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/i.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/i.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/j.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/j.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/k.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/k.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/l.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/l.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/m.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/m.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/n.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/n.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/o.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/o.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/p.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/p.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/r.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/r.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/cruising/s.jpg" data-lightbox="CruisingImages">
                        <img className="w16PrMt5" src="/grafika/slike/cruising/male/s.jpg" alt="tome" />
                    </a>
                </div>


                <div className="welcomeDiv">
                    <div className="h30w100p">
                        <h3>
                            {translation.GalleryDiving}
                        </h3>
                    </div>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/a.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/a.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/b.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/b.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/c.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/c.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/d.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/d.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/e.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/e.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/f.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/f.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/g.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/g.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/h.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/h.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/i.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/i.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/j.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/j.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/k.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/k.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/l.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/l.jpg" alt="tome" />
                    </a>
                </div>

                <div className="regulaMiddleRow">
                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/m.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/m.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/n.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/n.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/o.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/o.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/p.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/p.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/r.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/r.jpg" alt="tome" />
                    </a>

                    <a className="w16PrMt5-link" href="/grafika/slike/ronjenje/s.jpg" data-lightbox="DiveImages">
                        <img className="w16PrMt5" src="/grafika/slike/ronjenje/male/s.jpg" alt="tome" />
                    </a>
                </div>
            </div>
        </SRLWrapper>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(Gallery));