import React, { useState } from 'react';
import { Link } from "react-router-dom";
import LeftMenu from './LeftMenu';
import Links from './Links';
import RightSide from './RightSide';
import './Layout.css';
import TopFlags from './TopFlags';
import NonHighlightedLeftMenu from './NonHighlightedLeftMenu';

export default function Layout(props) {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className="container body-content">
            <div className="row" >
                <img src="/grafika/logo.jpg" className="logoImg d-none d-md-block" alt="logo" />
                <Link to="/">
                    <img src="/grafika/logo-small.png" className="logoImg d-md-none" alt="logo" />
                </Link>
            </div>
            <div className="d-md-none">
                <div className="row">
                    <div>
                        <button className="hamburger m-3" onClick={toggleMenu}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                            </svg>
                        </button>
                    </div>

                    <div className="m-3 ml-center">
                        <TopFlags />
                    </div>
                </div>
                <div className="row">
                    {showMenu && (
                        <div className="m-3" onClick={toggleMenu}>
                            <NonHighlightedLeftMenu />
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 d-none d-md-block">
                    <LeftMenu />
                </div>
                <div className="col-md-6">
                    <div className="p-4">
                        {props.children}
                    </div>
                </div>
                <div className="col-md-3">
                    <RightSide />
                </div>
            </div>
            <footer>
                <hr />
                <Links />
                <hr className="d-none d-md-block" />
                <div>&copy; {new Date().getFullYear()} - Tome Fishing</div>
            </footer>
        </div>
    )
}
