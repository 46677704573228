import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const FishingPlus = ({ data }) => {
    const { translation } = data;
    return (
        <div>
            <div className="welcomeDiv">
                <h2>
                    {translation.FishingPlusTitle}
                </h2>
            </div>

            <div className="subHeader">
                {translation.FishingWeekPlus}
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/fwp2.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/fwp1.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <p className="leftAlignParagraph" dangerouslySetInnerHTML={{ __html: translation.FishingPlusText }}>
                </p>
            </div>

            <div className="regulaMiddleRow">
                <p className="leftAlignParagraph" dangerouslySetInnerHTML={{ __html: translation.FishingPlusPackagePriceText }}>
                </p>
            </div>


            <div className="regulaMiddleRow">
                <img src="/grafika/fwp3.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="subHeader">
                {translation.SquidFishingWeek}
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/fwpsq.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/fwpsq2.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <img src="/grafika/fwpsq3.jpg" className="w420mt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                <p className="leftAlignParagraph" dangerouslySetInnerHTML={{ __html: translation.FishingPlusText2 }} />
            </div>
            <div className="regulaMiddleRow">
                <p className="leftAlignParagraph" dangerouslySetInnerHTML={{ __html: translation.FishingPlusPackagePriceText2 }} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(FishingPlus));