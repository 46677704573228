import React from 'react'
import { Link } from "react-router-dom";

function filterCustomName(translation, path) {
    if (path === 'RentABoat') return 'Rent a Boat';
    if (path === 'Video') return 'Video';
    return translation[path];
}

const filterCustomPath = path => path === 'Home' ? '' : path;

const classNameGetBy = (selection, path) => selection === path ? "nav-link active" : "nav-link";

export default function LeftMenuItem({ translation, pagePath, selection, onClick }) {
    return (
        <Link onClick={() => onClick && onClick()} className={classNameGetBy(selection, pagePath)} id="v-pills-home-tab"
            data-bs-toggle="pill"
            href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"
            to={`/${filterCustomPath(pagePath)}`}>{filterCustomName(translation, pagePath)}</Link>
    )
}
