const It = {
    LanguageSign: "ita",
    Home: "Home",
    HomeTitle: "Benvenuti !",
    HomeText: "Scoprite lo spirito del Mediterraneo d'una volta visitate i luoghi piu belli dell' Adriatico. Godete d'una gita con la barca per l'arcipelago delle Coronate, scoprite la gioa di pescare e risposatevi nel porto che si trova piu vicino alla belleza delle pietrose Coronate.",
    DailyCruisingTitle: "Crociere quotidiane",

    DailyCruisingText: "ll programme e destinato a tutti coloro che non hanno ne tempo ne possibilita di passare un periodo piu lungo al mare, per tutti coloro che sono situati negli alberghi e appartamenti privati, ma che cio nonostante vorrebbero scoprire la bellezza delle nostre isole, le spiagge solitarie, un acqua limpidissima e che vorrebbero provare diverse specialita di pesce.",

    ADVANTAGE: "CONVIENZA: La possibilita di cambiare il porto di partenza [Sali] se ci si mette d'accordo prima [Zadar,Sukosan].",

    Yes: "Si",
    No: "No",

    ProgramDesc: "Programa e dedicato per {0} persone max. e dura tra {1}.",

    Day: "Giorno",
    IncludedInPrice: "IL PREZZO INCLUDE:",

    NotIncludedInPrice: "IL PREZZO NON INCLUDE:",

    FullUseBoat: "L'uso della nave",

    SkipperExp: "Skipper, l'uso della barca",

    VhcDscGps: "VHC, DSC, Color GPS chart plotter",
    OutEngine: "Il motore esterno da 10 cavalli",
    KitchenUts: "Recipienti per il cibo (piatti, vasi)",
    FinCleaning: "La pulizia finale",
    Tax: "Le tasse",
    Insurance: "L'assicurazione della nave",
    FuelCost: "La benzina",
    FoodDrinkExp: "La spese di cibo e di bevanda (25 EUR / GIORNO / Persone)",
    SpecialOfferCruising: "OFFERTA SPECIALE CROCIERA - 400 EUR / GIORNO",
    KornatiRestaurantExp: "La spese per pranzo (pesce/carne) e di bevanda in ristorante sul isola Kornat",
    EntranceExp: "La spese per entrata (bighlietti) in PN Telascica e NP Kornati",

    MultidayCruisingTitle: "Crociere di piu Giorni",
    MultidayCruisingText: "II programma e destinato a tutti coloro vorrebbero conoscere pienamente le bellezze della Dalmazia, e le sue perle (vale a dire, le spiagge, le isole, gli scogli, le lagune ) ed anche a coloro che vorrebbero vivere un' esperienza indimenticabile in questa crociera. Tocca a voi di scegliere le destinazioni e il modo in cui passare il proprio tempo. Il ritmo di navigazione vi permete di vedere tantissime destinazioni e di tornare al porto di partenza. Cioe di tornare a Sali dove siete accomodati negli appartamenti. Lo scopo del programma e che ogni giorno voi scopriate nuove cose belle dell'Adriatico con tanto divertimento, facendo tanti bagni, mangiando del piatti tipici di pesce, e che vi sentiate comodi nei vostri appartamenti.",
    AccomodationAppartment: "La sistemazione negli appartamenti",

    BedAndTowels: "La copertine, gli asciugamani",
    AdditionalOffer: "OFFERTA SPECIALE: La preparazione di piatti di mare a bordo della nave, la consumazione di bevande.",
    ProgramDescShort: "Programa e dedicato per {0} persone max",


    DailyCruising: "Crociere Quotidiane",

    MultidayCruising: "Crociere di piu Giorni",

    DailyFishing: "Pesca di Giorno",

    EveningFishing: "Pesca di Sera",

    MultidayFishing: "Pesca di piu Giorni",

    FishingPlus: "Pesca Plus",

    Boat: "Barca",

    Port: "Porto D'appartenenza",

    Apartments: "Appartamenti",

    Contact: "Contattare",

    Gallery: "Galleria Foto",

    AboutUs: "Noi siamo",


    HowToFindUs: "Ci troviamo qui:",

    ContactOwner: "Come direttamente contattare il propietario:",

    ApartmentsSubtitle: "Appartamenti potete affittare anche separato dei programmi",

    ApartmentsDistance: "Distanza",

    ApartmentsOffer: "Offerta",

    ApartmentsPictures: "Foto della casa",

    ApartmentsObjectDesc: "La descrizioni della casa",

    ApartmentsApartments: "Apartamenti",

    ApartmentsApartment: "Appartamento",

    ApartmentsPriceADay: "Prezzo / Giorno",

    Owner: "Proprietario",

    HowToCome: "Come arrivare a Sali?",

    GalleryFishing: "Pesca",

    GalleryGrill: "Camino",

    GalleryCruising: "Crociere",

    GalleryDiving: "Diving",
    HouseDesc: "La casa si trova a Sali, Dugi otok, a 30m dal mare, in un posto bellissimo e tranquillo La spiaggia con un'acqua limpidissima si trova a 150m Vicino alle casa ci sono il negozio, la posta, il dotore, il ristorante, il bar.",




    DailyFishingTitle: "Pesca di Giorno",
    DailyFishingText: "La destinazione e‘ l'arcipelago delle Dugi otok - Coronate e vi stanno a disposizione varie tecniche di pesca (vi suggeriamo la combinazione di palamiti, traina, pesca con canna, pesca al mano, Big game fishing).",

    EveningFishingTitle: "Pesca di sera",
    EveningFishingText: "A pesca si va tardi da sera, si parte dal porto Sali sull'isola Dugi otok. La pesca di sera e la pesca notturna (bolentino, traina, palamiti, calamari). Ritorno al porto tardi di note.",

    MultidayFishingTitle: "Pesca di piu Giorni",
    MultidayFishingText: "Destinata a quelli che sono veramente innamorati della pesca e a tutti coloro che vorrebbero sentire Ia vera gioia di pescare! Siccome abbiamo piu giorni a nostra disposizione, il risultato sara ottimo e si utilizzeranno tutte Ie tecniche di pesca.",


    BoatTitle: "Barca",

    BoatText: "Merry Fisher 695 e motoscafo ideale per la pesca e per crociera. Grazie al cockpit vasto e spazioso Ia barca e' molto pratica per la pesca ed offre tanto comfort durante la navigazione. II diesel mottore di 160 cavalli vi assicura la velocita di navigazione di 23 nodi. La barca e' equipagiata di attrezzi piu' moderni per la navigazione (color gsp plotter. VHF DSC color fishfinder-sonder. Ia bussola) e per la barca (winch. bagno. Ia doccia esterna. letti. HI FI. salotto. cucina. il mottore aggiuntivo di 10 cavalli) che offre Ia sicurezza e il comfort per 6 partecipanti e equipaggio.",

    PortTitle: "Porto D'appartenenza",
    PortText: "Seli e Ia cittedina principale e piu grande sull'isola Dugi Otok. II nome 'Sali' deriva dall'attivita di produzione del sale svolta e sotto questo nome Sali si trova nei documenti dell'anno 1105.",

    PortText2: "E situata sulla costa nord est dell'isola ed ha una tradizione di pescatori di piu di mille anni (il primo documento scrito dell'anno 990 parla di pescatori e degli abitanti nella Telascica).La principali attivita di Sali sono Ia pesca. l'agricultura (olivi . vignete), turismo e piccole attivita familiari. E importante sottolineare che su tutte Ie isole non esiste nessuna fabrica, cioe nessuna fonte di inquimento. A Sali ci sono alcuni negozi di merce varia, la panetterie, trattorie, bar.Gli altri servizi sono l'ufficio del dottore, la polizia, il porto e la libreria. La vicinza di Sali al parco nazionale delle Coronate e al parco di bellezze naturali di Telascica.  Sali e la cittadina , lo vede (vecle), per uno geruno  un punto di riferimento che ha tutte Ie caratteristiche geruno sviluppo di turismo nautico, subacqueo, sportivo e di gruppi di persone.",

    ApartmentsTitle: "Appartamenti",
    ApartmentsText: "Diving",

    ContactTitle: "Diving",
    ContactText: "Se avete delle domande, per tutte informazioni e prenotazioni potete contattare direttamente il proprietario.",


    AboutUsTitle: "Noi Siamo",
    AboutUsText: "Tome e un'attivita familiare che si occupa di turismo. di pesca e di trasporto dei passeggeri. Il fondatore di quest'attivita e signore Tomislev Caric che e anche il vostro ospite. skipper,  l'organizzatore e la guida di pesca. ll proprio amore verso il mare si esprime nelle sue navigazione per tutti i mari del mondo in tutti i tipi di navi. L'esperienza aquistata al mare e l'amore e il fascino che sente per la pesca. li difonde cordialmente e  con tutto il cuore a  tutti i suoi ospiti.",

    Price: "Prezzo",


    FishingEquipment: "Attrezzatura da pesca",

    FishingLicense: "Licenza di pesca",

    FishingBaits: "La escha (sardella, calamari, vermi di mare, cozze, aguglie e imitazioni-rapalas)",
    EveningFishingText2: "",

    ProgramDescShortExt: "Programma e dedicato per {0} persons max and lasts according to a previous agreement",
    MultidayFishingText2: "Vi offriamo la possibilita di pescare ogni giorno, di gustare delle specialita di mare e con tutto cio offriamo aneche la sistemazione nei nostri appartamenti affinche da noi vi sentiate a proprio agio. ll porto di Sali e situato nell'arcipelago delle coronate e vi offre di provare tutti tipi di pesca, anche il Big Game Fishing.",

    FishingPlusTitle: "Pesca Plus",

    FishingPlusText: "7 NOTTI MEZZA PENSIONE: <br/>Alloggio in apartamento con pranzo <br/> (diversi piatti di pesce con vino di nostra produzione, olio di olive e verdure frecse, 25 EUR / GIORNO / Persone)<br/>2 GITE PESCA CON PALAMITI: <br/>Combinazioni con diversi tipi dei palamiti. Pomeriggio - note.<br/>2 GITA PESCA AL CALAMARI: <br/>Se non e staggione da calamari, altre tecnice(train, pesca con canna, al mano...). Pomeriggio - note.<br/>2 BIG GAME FISHING: Mattina - Pomeriggio.",

    FishingPlusPackagePriceText: "PREZZO SETTIMANALE PER PARTICIPANTE 800 EUR<BR/>Minimo numero partecipanti 2 e max 4<br/>Accessibile da 01.04.-31.12 ogni anno.",

    FishingPlusText2: "7 NOTTI MEZZA PENSIONE: <br/>Alloggio in apartamento con pranzo <br/> (diversi piatti di pesce con vino di nostra produzione, olio di olive e verdure frecse, 25 EUR / GIORNO / Persone)<br/>5 GITA PESCA AL CALAMARI: <br/>Si po combinare con palamiti Pomeriggio - note.<br/>1 CROCIERA IN PN TELASCICA E NATIONAL PARK KORNATI:<br/>Mattina - Pomeriggio.",


    FishingPlusPackagePriceText2: "PREZZO SETTIMANALE PER PARTICIPANTE 600 EUR<br/>Minimo numero dei partecipanti  3 e max 5<br/>Accessibile da 05.09.-31.12 ogni anno.",

    ResidencePermit: " LA TASSA DI SOGGIORNO<br/>1 EUR per persona al giorno<br/>0.5 EUR pet bambini 12-18 a. giorno",
    ApartmentAdvantage: " LA CONVIENZA (per gli ospiti dell'appartamento) <br/>-uno sconto per le gite e le gite di pesca di 15% <br/>-una cena di pesce e vino gratis per tutti che affitano l'appartamento per un periodo piu lungo di 7 giorni in maggio, giugno e in settembre. <br/>-una cena di pesce e vino gratis per tutti che affitano l'appartamento per un periodo piu lungo di 14 giorni in luglio ed agosto. <br/>-l'uso libero del camino <br/>-la possibilita di noleggiare una barca plastica (4.5 metri di lungezza,con il motore esterno da 9.8 cavalli)",

    Apartment4Text2: "In appartamento (stanza) si trova (2 letti), tavolo con due sedie e bagno.",
    Apartment3Text2: "Si trova sul piano terreno (laterale) ed ha una camera da letto ( 2 letti), la cucina con soggiorno ( 1 Ietto possibilità ), il bagno.",

    Apartment2Text2: "Si trova sul primo piano ed ha una camera da letto (2 letti).un'altra camera ( 2 Ietti), una camera (1 Ietto), Ia cucina, il bagno, una grande terrazza e balcone, cucina con soggiorno.",
    Apartment1Text2: "Si trova sul piano terreno ed ha una camera da Ietto (2 Ietti), un'altra camera da letto (2 letti). il soggiorno (1 Ietto. possibilita), Ia cucina, il bagno, il gabinetto e una terrazza grande con la doccia all'aperto.",
    ContactOwnerData: "capt. Tomislav Caric. <br/>Sali 23281, Dugi otok, Hrvatska<br/>tel. +385 (0)23 377 489<br/>gsm. +385 (0)98 629 353<br/>email: tomislav.caric2@gmail.com",
    ApartmentsSea: "Mare",
    ApartmentsBeach: "Spiaggia",
    ApartmentsBerth: "Ormeggio",
    ApartmentsAmbulance: "Ambulanza",
    ApartmentsExcursions: "Escursione",
    ApartmentsFishing: "Pesce",
    ApartmentsDiving: "Immersione",
    ApartmentsPets: "Animali domestici",
    ApartmentsBedsNumber: "Numero di letti",
    ApartmentsBarbecue: "Camino",
    ApartmentsSize: "Superficie [m2]",
    ApartmentsTerrace: "Terrazza",
    ApartmentsCategory: "Categoria",
    ApartmentsRoomsNumber: "Numero di camere",
    ApartmentsSeaView: "Vista al mare",
    ApartmentsSatTV: "Sat TV LCD",
    ApartmentsBathroom: "Bagno",
    ApartmentsAirCondition: "Air-Condition",
    ApartmentsWashmachine: "Lavatrice",
    ApartmentsDishWasher: "Lavapiatti",
    ApartmentsWIFI: "WIFI",
    ApartmentsFrigo: "Frigo",
    ContactaByCarTitle: "In machina",
    ContactaByCarText: "Da Zadar prendere il traghetto Zadar-Brbinj (cca 1h 30min). Arrivati al porto Brbinj sull'isola Dugi otok con la machina si prende la strada e dopo 20 chilometri (25 minuti circa) siete a Sali dove vi aspetta il proprietario.",
    ContactaWithoutCarTitle: "Senza machina",
    ContactaWithoutCarText: "Da Zadar si prende la nave Zadar-Sali (cca 1h 15min) e si prende una nave veloce Zadar-Sali (cca 35min) fino Sali dove vi aspetta il proprietario.",
    ContactaSpecOfferTitle: "Offerta speciale",
    ContactaSpecOfferText: "Il transporto da Zadar a Sali a bordo della barca proprietario (cca 60min) se ci si mette d'accordo.",
    BoatLength: "Lunghezza",
    BoatBeam: "Larghezza",
    BoatEngine: "Motor",
    BoatDraught: "Bozza",
    BoatBeds: "Giaciglio",
    BoatWC: "WC",
    BoatFuel: "Benzina",
    BoatWater: "Acqua",
    BoatRadar: "Radar",
    FishingWeekPlus: "PESCA SETTIMANA PLUS",
    SquidFishingWeek: "CALAMARO PESCA SETTIMANA"
}

export default It;