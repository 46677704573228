import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const EveningFishing = ({ data }) => {
    const { translation } = data;
    return (
        <div>
            <div className="welcomeDiv">
                <h2>
                    {translation.EveningFishingTitle}
                </h2>
            </div>


            <div className="row">
                <div className="col-lg-6">
                    <p>
                        {translation.EveningFishingText}
                    </p>
                </div>
                <div className="col-lg-6">
                    <img src="/grafika/ef.jpg" className="contenTable" alt="tome" />
                </div>
            </div>

            <div className="regulaMiddleRow">
                <p>
                    {translation.EveningFishingText2}
                </p>
            </div>


            <div className="regulaMiddleRow">
                <img src="/grafika/vecriba.jpg" className="w49PrMt20" alt="tome" />
                <img src="/grafika/vecribb.jpg" className="w49PrMt20" alt="tome" />
            </div>

            <div className="regulaMiddleRow">
                {translation.Price} 350 eur
            </div>

            <div className="row">
                <div className="col-lg-12">
                    {translation.IncludedInPrice}
                    <ul>
                        <li>{translation.SkipperExp}</li>
                        <li>{translation.FishingEquipment}</li>
                        <li>{translation.FishingLicense}</li>
                        <li>{translation.FishingBaits}</li>
                        <li>{translation.FuelCost}</li>
                        <li>{translation.VhcDscGps}</li>
                        <li>{translation.OutEngine}</li>
                        <li>{translation.FinCleaning}</li>
                        <li>{translation.Insurance}</li>
                        <li>{translation.Tax}</li>
                    </ul>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    {translation.NotIncludedInPrice}
                    <ul>
                        <li>{translation.FoodDrinkExp}</li>
                    </ul>
                </div>
            </div>

            <div className="regulaMiddleRow">
                <p>
                    {translation.ProgramDesc.replace('{0}', '4').replace('{1}', '18:00-24:00')}
                </p>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ data: state.languageReducer });

export default connect(mapStateToProps)(withRouter(EveningFishing));
